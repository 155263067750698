import React, { useEffect, useState } from "react";
import {
  Button,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  Stack,
  FormControl,
  InputLabel,
} from "@mui/material";

import {
  useGetUsersQuery,
  useUploadEmployeeMutation,
  useDeleteEmployeeMutation,
} from "../../../app/api/users/usersApiSlice";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import DISTable from "../../../components/tables/DISTable";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import CustomizedMenus from "../../../components/buttons/CustomizeMenu";
import fields from "./employeCSVImport";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AlertDialog from "../../../components/modal/AlertDialog";
import { useLocation } from "react-router-dom";
import IconBreadcrumbs from "../../../components/shared/Breadcrumb";
import { ReactComponent as SickLeaveIcon } from "../../../assets/sick leave.svg";
import { ModalData } from "../../../components/modal/Modal";
import LeaveFormData from "./leaveForm/LeaveFormData";

const headers = [
  {
    id: "fullname",
    label: "Full Name",
    sortable: true,
    searchable: true,
  },
  {
    id: "position",
    label: "Position",
    sortable: true,
    filterable: true, // Enabling filter for this field
  },
  {
    id: "caddress",
    label: "Complete Address",
    sortable: false,
    filterable: false, // Enabling filter for this field
  },
  {
    id: "area_assign",
    label: "Area Assign",
    sortable: true,
    filterable: true, // Enabling filter for this field
  },
  {
    id: "contact",
    label: "Contact No.",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

function EmployeeDataN() {
  const { branch_no, id: user_log_id, isSuperAdmin } = useAuth();
  const [users, setUsers] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogId, setDialogId] = useState(null);
  const [uploadEmployee] = useUploadEmployeeMutation();
  const [deleteEmployee] = useDeleteEmployeeMutation();

  const [clientStatus, setClientStatus] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");

  /* used for table */
  const [totalPageNumber, setTotalPageNumber] = useState(0);
  const [position, setPosition] = useState([]);
  const [areaAssign, setAreaAssign] = useState([]);

  const [page, setPage] = useState(0);
  const [positionSelected, setPositionSelected] = useState("");
  const [areaAssignSelected, setAreaAssignSelected] = useState("");
  const [positionData, setPositionData] = useState([]);
  const [areaAssignData, setAreaAssignData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();

  const navigate = useNavigate();

  const [workStatus, setWorkStatus] = useState("");

  const getQueryParams = (search) => {
    const params = new URLSearchParams(search);
    return {
      status: params.get("status"),
    };
  };

  const queryParams = getQueryParams(location.search);
  const [filters, setFilters] = useState({
    position: "",
    area_assign: "",
    workStatus: parseInt(queryParams.status) || "",
  });

  /* for modal */
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");

  useEffect(() => {
    if (queryParams.status) {
      setWorkStatus(queryParams.status);
      setFilters({
        ...filters,
        workStatus: parseInt(queryParams.status),
      });
    }
  }, [queryParams.status]);

  const { data: usersData, isLoading: isUsersLoading } = useGetUsersQuery(
    {
      ...(!isSuperAdmin && { branch_id: branch_no }),
      id: "all",
      page: page + 1,
      search: searchValue,
      filters: filters,
      rowsPerPage: rowsPerPage,
      workStatus: workStatus,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const options = {
    allowEdit: true,
    allowDelete: true,
    allowView: true,
    allowAdd: true,
    allowImport: true,
    allowExport: true,
    allowSearch: true,
    numberPerPage: 10,
    selectable: false,
    page: setPage,
    totalPageNumber: 0,
    tableTitle: "",
    isLoading: isLoading,
    setSearchValue: setSearchValue,
    searchValue: searchValue,
    setIsLoading: setIsLoading,
    setRowsPerPage: setRowsPerPage,
  };

  useEffect(() => {
    if (isUsersLoading === false && usersData) {
      let users = usersData[0].data.map((user) => {
        return {
          id: user.id,
          fullname: `${user.last_name}, ${user.first_name} ${user.middle_name}`,
          first_name: user.first_name,
          last_name: user.last_name,
          position: user.position,
          area_assign: user.assigned_area,
          caddress: user.address,
          contact: user.phoneNumber,
          status: user.status === 0 ? "Active" : "Inactive",
        };
      });

      const positionFilter = usersData[0].position.map((pos) => pos.name);
      const areaAssignFilter = usersData[0].assignmentAreas.map(
        (area) => area.name
      );

      setPositionData(usersData[0].position);
      setAreaAssignData(usersData[0].assignmentAreas);
      setPosition(positionFilter);
      setAreaAssign(areaAssignFilter);
      setUsers(users);
      setTotalPageNumber(
        usersData[0].totalCount[0] ? usersData[0].totalCount[0].count : 0
      );
    }
  }, [usersData, page, isUsersLoading]);

  options.totalPageNumber = totalPageNumber;

  const handleDelete = (id, status) => {
    setDialogOpen(true);
    setDialogId(id);
    setClientStatus(status);
    setDialogMessage(
      status === "Active"
        ? "Are you sure you want to deactivate this employee?"
        : "Are you sure you want to activate this employee?"
    );
  };

  const handleYesDelete = async () => {
    try {
      const res = await deleteEmployee({
        id: dialogId,
        status: clientStatus === "Active" ? 1 : 0,
        type: "employee",
      });

      console.log(res?.status);

      if (res) {
        toast.success(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleView = (id) => {
    navigate(`/manage-employees/view/${id}`);
  };

  const handleImport = async (data) => {
    try {
      const formData = new FormData();
      formData.append("uploadData", JSON.stringify(data.validData));
      formData.append("branch_id", branch_no);
      formData.append("added_by", user_log_id);

      const res = await uploadEmployee({
        formData: formData,
        type: "employee",
      }).unwrap();
      console.log(res.status);
      if (res.message) {
        toast.success(res.message + " with " + res.numberOfDup + " duplicates");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleImportClose = () => {
    setIsOpen(false);
  };

  const handleChange = (event) => {
    setIsLoading(true);
    setFilters({
      ...filters,
      workStatus: event.target.value,
    });
    setWorkStatus(event.target.value);
  };

  const handleSickLeave = () => {
    setOpenModal(true);
    setModalType("requestLeave");
    setModalTitle("Request Leave");
    setModalContent(<LeaveFormData />);
  };

  const custMenuOption = [
    {
      label: "Import Employee",
      icon: <GroupAddIcon />,
      action: () => {
        setIsOpen(true);
      },
    },
    {
      label: "Download Employee Template",
      icon: <GroupAddIcon />,
      action: () => {
        window.open("/csv-template/payroll-format.csv", "_blank");
      },
    },
  ];

  const customButton = (
    <>
      <Stack
        direction={{
          xs: "column",
          sm: "row",
        }}
        spacing={2}
        sx={{ width: "100%", minWidth: "600px" }}
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<SickLeaveIcon style={{ width: "20px" }} />}
          onClick={() => handleSickLeave()}
          sx={{ mr: 1, width: "100%", minHeight: "38px", height: "100%" }}
        >
          Request Leave
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/manage-employees/add")}
          sx={{ mr: 1, width: "100%", minHeight: "38px", height: "100%" }}
        >
          Add Employee
        </Button>

        <CustomizedMenus options={custMenuOption} />
      </Stack>
    </>
  );

  const customDropdownFilter = (
    <>
      <Autocomplete
        id="position-autocomplete"
        options={position} // Array of positions
        value={positionSelected}
        onChange={(event, newValue) => {
          setIsLoading(true);
          setPositionSelected(newValue);
          setFilters({
            ...filters,
            position:
              positionData.find((pos) => pos.name === newValue)?.id || "",
          });
        }} // Handle selection
        renderInput={(params) => (
          <TextField
            {...params}
            label="Position"
            placeholder="Position (ALL)"
            sx={{
              "& .MuiInputBase-root": {
                height: "38px", // Adjust height
                paddingTop: 0,
                paddingBottom: 0,
                fontSize: "12px",
              },

              "& .MuiInputLabel-root": {
                transform: "translate(14px, -8px) scale(0.75)", // Adjust position of label
                fontSize: "12px",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                transform: "translate(14px, -8px) scale(0.75)", // Adjust label when focused
              },
            }}
            InputLabelProps={{ shrink: true }} // Always shrink the label
          />
        )}
        sx={{ minWidth: "150px" }}
        fullWidth
        className="autoCompleteHeader"
        isOptionEqualToValue={(option, value) => option === value} // Ensure proper comparison
        getOptionLabel={(option) => (option ? option : "")} // Handle null/undefined option
      />

      <Autocomplete
        id="area-assign"
        options={areaAssign} // Array of area assign
        value={areaAssignSelected}
        onChange={(event, newValue) => {
          setIsLoading(true);
          setAreaAssignSelected(newValue);
          setFilters({
            ...filters,
            area_assign:
              areaAssignData.find((area) => area.name === newValue)?.id || "",
          });
        }} // Handle selection
        renderInput={(params) => (
          <TextField
            {...params}
            label="Area Assign"
            placeholder="Area Assign (ALL)"
            sx={{
              "& .MuiInputBase-root": {
                height: "38px", // Adjust height
                paddingTop: 0,
                paddingBottom: 0,
                fontSize: "12px",
              },
              "& .MuiInputLabel-root": {
                transform: "translate(14px, -8px) scale(0.75)", // Adjust position of label
                fontSize: "12px",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                transform: "translate(14px, -8px) scale(0.75)", // Adjust label when focused
              },
            }}
            InputLabelProps={{ shrink: true }} // Always shrink the label
          />
        )}
        sx={{ minWidth: "150px" }}
        fullWidth
        isOptionEqualToValue={(option, value) => option === value} // Ensure proper comparison
        getOptionLabel={(option) => option || ""} // Display the area assign name
      />

      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel
          id="demo-select-small-label"
          sx={{
            fontSize: "12px",
            height: "38px",
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          Work Status
        </InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={workStatus}
          label="Work Status"
          onChange={handleChange}
          sx={{
            fontSize: "12px",
            height: "38px",
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <MenuItem value={""}>None</MenuItem>
          <MenuItem value={0}>Active</MenuItem>
          <MenuItem value={1}>Inactive</MenuItem>
        </Select>
      </FormControl>
    </>
  );

  const actions = {
    handleSwitchOnOff: handleDelete,
    handleView,
    //handlePrint,
  };

  return (
    <>
      <IconBreadcrumbs
        data={[
          { link: "/dashboard", title: "Home" },
          { link: "/manage-employees", title: "Manage Employees" },
        ]}
        title="Manage Employees"
      />
      <DISTable
        headers={headers}
        data={users}
        options={options}
        actions={actions}
        customButton={customButton}
        others={customDropdownFilter}
      />
      <ReactSpreadsheetImport
        isOpen={isOpen}
        onClose={handleImportClose}
        onSubmit={handleImport}
        fields={fields}
      />
      <ToastContainer />
      <AlertDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        title="Update Status"
        content={dialogMessage}
        dialogId={dialogId}
        action={handleYesDelete}
      />
      <ModalData
        closeModal={() => setOpenModal(false)}
        openModal={openModal}
        title={modalTitle}
        type={modalType}
        profileName=""
        cameraImg=""
        bodyContent={modalContent}
      />
    </>
  );
}

export default EmployeeDataN;
