import React from "react";
import { useParams } from "react-router-dom";
import { Box, Card, CardContent, ButtonBase } from "@mui/material";
import {
  SupervisedUserCircle as SupervisedUserCircleIcon,
  Assignment as AssignmentIcon,
} from "@mui/icons-material";

//import "./HRStyle.css";

function Reports() {
  const { id, type } = useParams();
  let content = null;
  switch (type) {
    case "manage-users":
      //content = <UserListData />;
      break;
    case "manage-clients":
      //content = <ManageAOR />;
      break;
    default:
      content = (
        <>
          <Box
            sx={{
              gridTemplateColumns: "repeat(3, 1fr)", // 3 columns
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              width: "100%",
              height: "500px",
              alignItems: "center",
            }}
            className="administrative-card"
          >
            {[
              {
                href: "#",
                label: "PAYSLIP",
                icon: (
                  <SupervisedUserCircleIcon
                    sx={{
                      fontSize: 50,
                      color: "primary.main",
                    }}
                  />
                ),
              },
              {
                href: "#",
                label: "PAYROLL",
                icon: (
                  <AssignmentIcon
                    sx={{
                      fontSize: 50,
                      color: "primary.main",
                    }}
                  />
                ),
              },
              {
                href: "#",
                label: "13TH MONTH PAY",
                icon: (
                  <AssignmentIcon
                    sx={{
                      fontSize: 50,
                      color: "primary.main",
                    }}
                  />
                ),
              },
              {
                href: "#",
                label: "CLIENT LIST",
                icon: (
                  <AssignmentIcon
                    sx={{
                      fontSize: 50,
                      color: "primary.main",
                    }}
                  />
                ),
              },
              {
                href: "#",
                label: "LEAVE",
                icon: (
                  <AssignmentIcon
                    sx={{
                      fontSize: 50,
                      color: "primary.main",
                    }}
                  />
                ),
              },
              {
                href: "#",
                label: "EXPIRED LICENSE",
                icon: (
                  <AssignmentIcon
                    sx={{
                      fontSize: 50,
                      color: "primary.main",
                    }}
                  />
                ),
              },
            ].map((item, index) => (
              <ButtonBase
                key={index}
                href={item.href}
                sx={{
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "center",
                  width: "200px",
                  height: "200px",
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: 3,
                  },
                  marginRight: "10px",
                }}
              >
                <Card
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    {item.icon}
                    <p style={{ margin: "0" }}>{item.label}</p>
                  </CardContent>
                </Card>
              </ButtonBase>
            ))}
          </Box>
        </>
      );
      break;
  }
  return content;
}

export default Reports;
