import { List, Paper, Typography, ListItem } from "@mui/material";
import React, { useEffect } from "react";
import { useExpiredLicenseWidgetQuery } from "../../../app/api/reports/dashboardApiSlice";
import useAuth from "../../../hooks/useAuth";
import dayjs from "dayjs";

function ExpiredWidget() {
  const { branch_no, dashboard } = useAuth();
  const [expiredLicense, setExpiredLicense] = React.useState([]);

  const { data, error, isLoading } = useExpiredLicenseWidgetQuery(
    { ...(dashboard === true ? { branch_no: branch_no } : {}) },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (data) {
      const expData = data.map((item) => {
        return {
          name:
            item.personal_info.first_name + " " + item.personal_info.last_name,
          license: item.personal_info.liscenseNo,
          expiration: dayjs(item.personal_info.expiryDate).format("MM/DD/YYYY"),
        };
      });

      setExpiredLicense(expData);
    }
  }, [data]);

  let contentList = (
    <List
      sx={{
        maxHeight: "370px",
        overflowY: "auto",
      }}
    >
      {expiredLicense.map((item, index) => (
        <ListItem key={index}>
          <strong>{item.name}</strong> - {item.license} - {item.expiration}
        </ListItem>
      ))}
    </List>
  );

  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Typography
        variant="h6"
        sx={{
          fontWeight: 700,
          textAlign: "center",
          backgroundColor: "#E1F7F5",
          color: "black",
        }}
      >
        ID and License Status
      </Typography>
      {contentList}
    </Paper>
  );
}

export default ExpiredWidget;
