import { useSelector } from "react-redux";
import {
  selectCurrentToken,
  selectCurrentObjId,
} from "../app/api/auth/authSlice";
import { jwtDecode } from "jwt-decode";
import { useGetUserPermissionQuery } from "../app/api/users/userPermission";
import { useEffect, useState } from "react";

const useAuth = () => {
  const [currentPermission, setCurrentPermission] = useState(false);
  const token = useSelector(selectCurrentToken);
  const objId = useSelector(selectCurrentObjId);

  let isManager = false;
  let isAdmin = false;
  let status = 10001;

  const { data: permission } = useGetUserPermissionQuery(
    { id: objId },
    { skip: !objId, refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (permission) {
      const perm = permission.map((p) => {
        setCurrentPermission(p.permissions[0]);
      });
    }
  }, [permission]);

  if (token) {
    const decoded = jwtDecode(token);
    const {
      username,
      roles,
      image_logo,
      branch_name,
      branch_no,
      id,
      _id,
      assign_id,
      area_assign,
    } = decoded.UserInfo;

    isManager = roles.includes(1001);
    isAdmin = roles.includes(999);

    if (isManager) status = "Manager";
    if (isAdmin) status = "Admin";
    return {
      username,
      roles,
      status,
      isManager,
      isAdmin,
      image_logo,
      branch_name,
      branch_no,
      id,
      assign_id,
      area_assign,
      local_user_id: _id,
      dashboard:
        currentPermission === false
          ? false
          : currentPermission?.dashboard?.viewAll,
      isSuperAdmin:
        currentPermission === false ? false : currentPermission?.isSuperAdmin,
    };
  }

  return {
    username: "",
    roles: [],
    isManager,
    isAdmin,
    status,
    branch_no: "",
    id: "",
    image_logo: "",
    branch_name: "",
    assign_id: "",
    area_assign: "",
    local_user_id: "",
  };
};
export default useAuth;
