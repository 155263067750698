import {
  Box,
  Stack,
  Typography,
  TextField,
  Grid,
  Select,
  MenuItem,
  Button,
  Paper,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

function BranchData() {
  const navigate = useNavigate();
  const handleAddBranch = () => {
    navigate("/manage-client/add");
  };
  return (
    <Paper elevation={3} sx={{ p: 2, backgroundColor: "#D1F3F1" }}>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Box
          component="form"
          validate="true"
          sx={{ mt: 1 }}
          className="memberFormData">
          <h1>Client Table Data</h1>
          <Button variant="outlined" color="primary" onClick={handleAddBranch}>
            <PersonAddIcon /> New Client
          </Button>
        </Box>
      </Paper>
    </Paper>
  );
}

export default BranchData;
