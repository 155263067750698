import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import IconMenu from "../../../../components/buttons/ButtonList";
import { useReactToPrint } from "react-to-print";
import {
  Grid,
  Stack,
  Typography,
  Card,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  MenuItem,
  Select,
  Box,
} from "@mui/material";
import { useGetPayrollQuery } from "../../../../app/api/payroll/generatePayroll";
import "./viewPayroll.css";

function decimalFormat(num) {
  const convertedNum =
    num !== null && num !== undefined
      ? num.toLocaleString("en-US", {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : "0.00";
  return convertedNum;
}
export default function ViewPayroll() {
  const location = useLocation();
  const [tableRow, setTableRow] = React.useState([]);
  const [totalRow, setTotalRow] = React.useState([]);
  const [selectedArea, setSelectedArea] = React.useState(""); // State for selected areaAssign
  const [areaOptions, setAreaOptions] = React.useState([]); // State for available areaAssign options

  const componentRef = React.useRef();
  const styleRef = useRef();

  const getQueryParams = (search) => {
    const params = new URLSearchParams(search);
    return {
      month: params.get("month"),
      year: params.get("year"),
      period: params.get("period"),
      branch_id: params.get("branch_id"),
      user_id: params.get("user_id"),
    };
  };

  const queryParams = getQueryParams(location.search);

  const { data, isLoading } = useGetPayrollQuery(
    {
      month: queryParams.month,
      year: queryParams.year,
      period: queryParams.period,
      branch_id: queryParams.branch_id,
      user_id: queryParams.user_id,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const handleAreaChange = (e) => {
    console.log(e.target.value);
    setSelectedArea(e.target.value);
  };
  useEffect(() => {
    if (data) {
      // Group data by 'area_assign'
      const groupedData = data.reduce((acc, row) => {
        const key = row.area_assign || null;

        if (!acc[key]) {
          acc[key] = [];
        }

        acc[key].push(row);

        return acc;
      }, {});

      setAreaOptions(Object.keys(groupedData));

      // Filter the data based on selectedArea
      // Filter the data based on selectedArea
      const filteredData = selectedArea
        ? { [selectedArea]: groupedData[selectedArea] || [] } // Keep the structure of groupedData when filtering by area
        : groupedData;

      const totals = {
        salaryRate: 0,
        daysWork: 0,
        totalSalary: 0,
        addPay: 0,
        rdPay: 0,
        adjAlow: 0,
        cas: 0,
        para: 0,
        rice: 0,
        v1: 0,
        v2: 0,
        hc: 0,
        licId: 0,
        cb: 0,
        ssslSssc: 0,
        phic: 0,
        mplPagc: 0,
        calOther: 0,
        ut: 0,
        totalDeduction: 0,
        netPay: 0,
        overtime: 0,
      };

      // Create table rows
      const totalRow = [];
      const rows = Object.keys(filteredData).map((areaAssign) => (
        <React.Fragment key={areaAssign}>
          <TableRow>
            <TableCell
              colSpan={27}
              sx={{
                textAlign: "left !important",
                fontSize: "16px",
                fontWeight: 700,
              }}
            >
              {areaAssign !== "null" ? areaAssign : "No Area Assign"}
            </TableCell>
          </TableRow>

          {filteredData[areaAssign].map((row) => {
            // Update totals
            let totalDeductionRowData =
              row.v1 +
                row.v2 +
                row.par +
                row.ut +
                row.absent +
                row.par +
                row.rice +
                row.sss_rate +
                row.philhealth_rate +
                row.payment || 0 + row.pagibig_rate;

            totals.salaryRate += parseFloat(row.salary_rate);
            totals.daysWork += parseFloat(row.days_work);
            const rowTotalSalary =
              parseFloat(row.days_work) * parseFloat(row.salary_rate);
            totals.totalSalary += rowTotalSalary;

            totals.overtime += parseFloat(row.overtime || 0);
            totals.addPay += parseFloat(row.payment || 0);
            totals.rdPay += parseFloat(row.rd_pay || 0);
            totals.adjAlow += parseFloat(row.adj_alow || 0);
            totals.cas += parseFloat(row.advance || 0);
            totals.para += parseFloat(row.para || 0);
            totals.rice += parseFloat(row.rice || 0);
            totals.v1 += parseFloat(row.v1 || 0);
            totals.v2 += parseFloat(row.v2 || 0);
            totals.hc += parseFloat(row.hc || 0);
            totals.licId += parseFloat(row.lic_id || 0);
            totals.cb += parseFloat(row.cb || 0);
            totals.ssslSssc += parseFloat(row.sssl_sssc || 0);
            totals.phic += parseFloat(row.phic || 0);
            totals.mplPagc += parseFloat(row.mpl_pagc || 0);
            totals.calOther += parseFloat(row.cal_other || 0);
            totals.ut += parseFloat(row.ut || 0);
            totals.totalDeduction += parseFloat(totalDeductionRowData || 0);
            const netPay =
              rowTotalSalary - parseFloat(totalDeductionRowData || 0);
            totals.netPay += netPay;

            return (
              <TableRow
                key={row.id}
                style={{ border: row.reliever ? "2px solid red" : "" }}
              >
                <TableCell
                  align="left"
                  sx={{ fontWeight: 700, fontSize: "14px" }}
                >{`${row.last_name}, ${row.first_name}`}</TableCell>
                <TableCell align="left">
                  {decimalFormat(row.salary_rate)}
                </TableCell>
                <TableCell align="left">
                  {decimalFormat(row.days_work)}
                </TableCell>
                <TableCell align="left">
                  {decimalFormat(rowTotalSalary)}
                </TableCell>
                <TableCell align="left">{row.nd}</TableCell>
                <TableCell align="left">{row.overtime}</TableCell>
                <TableCell align="left">{row.hol}</TableCell>
                <TableCell align="right">{row.payment}</TableCell>
                <TableCell align="right">{row.rd_pay}</TableCell>
                <TableCell align="right">{row.adj_alow}</TableCell>
                <TableCell align="right">
                  {decimalFormat(row.total_salary)}
                </TableCell>
                <TableCell align="right">{row.advance}</TableCell>
                <TableCell align="right">{row.para}</TableCell>
                <TableCell align="right">{row.rice}</TableCell>
                <TableCell align="right">{row.v1}</TableCell>
                <TableCell align="right">{row.v2}</TableCell>
                <TableCell align="right">{row.hc}</TableCell>
                <TableCell align="right">{row.lic_id}</TableCell>
                <TableCell align="right">{row.cb}</TableCell>
                <TableCell align="right">{row.sssl_sssc}</TableCell>
                <TableCell align="right">{row.phic}</TableCell>
                <TableCell align="right">{row.mpl_pagc}</TableCell>
                <TableCell align="right">{row.cal_other}</TableCell>
                <TableCell align="right">{row.ut}</TableCell>
                <TableCell align="right">
                  {decimalFormat(totalDeductionRowData)}
                </TableCell>
                <TableCell align="right">{decimalFormat(netPay)}</TableCell>
                <TableCell align="left">{row.signature}</TableCell>
              </TableRow>
            );
          })}
        </React.Fragment>
      ));

      // Add totals row at the end
      totalRow.push(
        <TableRow key="totals" className="totalPayroll">
          <TableCell align="left" sx={{ fontWeight: 700, fontSize: "14px" }}>
            Totals
          </TableCell>
          <TableCell align="left">{decimalFormat(totals.salaryRate)}</TableCell>
          <TableCell align="left">{decimalFormat(totals.daysWork)}</TableCell>
          <TableCell align="left">
            {decimalFormat(totals.totalSalary)}
          </TableCell>
          <TableCell align="left" />
          <TableCell align="left">{decimalFormat(totals.overtime)}</TableCell>
          <TableCell align="left" />
          <TableCell align="right">{decimalFormat(totals.addPay)}</TableCell>
          <TableCell align="right">{decimalFormat(totals.rdPay)}</TableCell>
          <TableCell align="right">{decimalFormat(totals.adjAlow)}</TableCell>
          <TableCell align="right">
            {decimalFormat(totals.totalSalary)}
          </TableCell>
          <TableCell align="right">{decimalFormat(totals.cas)}</TableCell>
          <TableCell align="right">{decimalFormat(totals.para)}</TableCell>
          <TableCell align="right">{decimalFormat(totals.rice)}</TableCell>
          <TableCell align="right">{decimalFormat(totals.v1)}</TableCell>
          <TableCell align="right">{decimalFormat(totals.v2)}</TableCell>
          <TableCell align="right">{decimalFormat(totals.hc)}</TableCell>
          <TableCell align="right">{decimalFormat(totals.licId)}</TableCell>
          <TableCell align="right">{decimalFormat(totals.cb)}</TableCell>
          <TableCell align="right">{decimalFormat(totals.ssslSssc)}</TableCell>
          <TableCell align="right">{decimalFormat(totals.phic)}</TableCell>
          <TableCell align="right">{decimalFormat(totals.mplPagc)}</TableCell>
          <TableCell align="right">{decimalFormat(totals.calOther)}</TableCell>
          <TableCell align="right">{decimalFormat(totals.ut)}</TableCell>
          <TableCell align="right">
            {decimalFormat(totals.totalDeduction)}
          </TableCell>
          <TableCell align="right">{decimalFormat(totals.netPay)}</TableCell>
          <TableCell align="left" />
        </TableRow>
      );

      setTableRow(rows);
      setTotalRow(totalRow);
    }
  }, [data, selectedArea]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Payroll Report",
    onBeforeGetContent: () => {
      // Dynamically create and add the print styles
      const styleElement = document.createElement("style");
      styleElement.innerHTML = `
        @media print {
          @page {
            size: 8.5in 14in landscape; /* Set size to long (legal) and landscape */
          }
          .print-content {
            font-size: 16px;
            color: black;
          }
          .tablePayrollContainer {
            page-break-inside: auto;
          }
          .tablePayrollContainer table {
            width: 100%;
            border-collapse: collapse;
          }
          .tablePayrollContainer thead {
            display: table-header-group;
          }
          .tablePayrollContainer tbody {
            display: table-row-group;
          }
          .tablePayrollContainer tr {
            page-break-inside: auto;
          }
          .tablePayrollContainer tr {
            break-inside: avoid;
          }
        }
      `;
      document.head.appendChild(styleElement);
      styleRef.current = styleElement; // Store reference to the style element
    },
    onAfterPrint: () => {
      // Remove the dynamically added style after printing
      if (styleRef.current) {
        document.head.removeChild(styleRef.current);
      }
    },
  });

  return (
    <>
      <IconMenu type={"EmployeeDTR"} handlePrint={handlePrint} />
      <Paper
        elevation={3}
        sx={{ width: "100%" }}
        className="payrollPaperContainer"
        ref={componentRef}
      >
        <Box sx={{ width: "400px" }} className="no-print">
          <Select
            value={selectedArea}
            onChange={handleAreaChange}
            displayEmpty
            fullWidth
            variant="outlined"
          >
            <MenuItem value="">
              <em>All Areas</em>
            </MenuItem>
            {areaOptions.map((area) => (
              <MenuItem key={area} value={area}>
                {area}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Typography variant="h6" align="center" gutterBottom>
          PICHEL SECURITY AND DETECTIVE AGENCY
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Stack direction={"column"} spacing={1}>
              DEPARTMENT: GEN GUARDS - PICHEL <br /> Branch:{" "}
              {queryParams.branch_id ? "Pichel Agency" : "Eminence Agency"}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="h6"
              align="center"
              sx={{ fontWeight: 700 }}
              gutterBottom
            >
              *****PAYROLL LISTING*****
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ textAlign: "right" }}>
            PAYROLL PERIOD:{" "}
            {`${parseInt(queryParams.period) === 1 ? 15 : 30}/${
              queryParams.month
            }/${queryParams.year}`}
          </Grid>
        </Grid>

        <TableContainer className="tablePayrollContainer">
          <Table className="table" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow className="tableHeaderColumn">
                <TableCell
                  align="center"
                  sx={{ width: "200px", fontWeight: 700 }}
                >
                  EMPLOYEE NAME
                </TableCell>
                <TableCell align="center" sx={{ width: "50px" }}>
                  RATE
                </TableCell>
                <TableCell align="center">DAYS</TableCell>
                <TableCell align="center">BASIC</TableCell>
                <TableCell align="center">ND</TableCell>
                <TableCell align="center">OT</TableCell>
                <TableCell align="center">HOL</TableCell>
                <TableCell align="center">ADD PAY</TableCell>
                <TableCell align="center">RD[Hrs] PAY</TableCell>
                <TableCell align="center">ADJ/ALOW</TableCell>
                <TableCell align="center">GROSS</TableCell>
                <TableCell align="center">CAS</TableCell>
                <TableCell align="center">PARA</TableCell>
                <TableCell align="center">RICE</TableCell>
                <TableCell align="center">V1</TableCell>
                <TableCell align="center">V2</TableCell>
                <TableCell align="center">HC</TableCell>
                <TableCell align="center">LIC. ID</TableCell>
                <TableCell align="center">CB</TableCell>
                <TableCell align="center">SSSL SSSC</TableCell>
                <TableCell align="center">PHIC</TableCell>
                <TableCell align="center">MPL PAGC</TableCell>
                <TableCell align="center">CAL OTHR</TableCell>
                <TableCell align="center">UT</TableCell>
                <TableCell align="center">TOTAL DEDUC</TableCell>
                <TableCell align="center">NET PAY</TableCell>
                <TableCell align="center">SIGNATURE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableRow}
              {totalRow}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container spacing={2} className="payrollPrepared">
          <Grid item xs={12} sm={4}>
            Prepared by: ___________________________
          </Grid>
          <Grid item xs={12} sm={4}>
            Checked by: ___________________________
          </Grid>
          <Grid item xs={12} sm={4}>
            Approved by: ___________________________
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
