import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../apiSlice";

export const userPositionAdapter = createEntityAdapter();

const initialState = userPositionAdapter.getInitialState();

export const userPositionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserPosition: builder.query({
      query: () => "/job-position",
      providesTags: (result, error, arg) => [
        { type: "UserPosition", id: "new" },
      ],
    }),
    addUserPosition: builder.mutation({
      query: (formData) => {
        const headers =
          formData instanceof FormData
            ? {}
            : { "Content-Type": "application/json" };

        return {
          url: "/job-position",
          method: "POST",
          body: formData,
          headers,
        };
      },
      invalidatesTags: [{ type: "UserPosition", id: "new" }],
    }),
    updateUserPosition: builder.mutation({
      query: (formData) => {
        const headers =
          formData instanceof FormData
            ? {}
            : { "Content-Type": "application/json" };

        return {
          url: "/job-position",
          method: "PUT",
          body: formData,
          headers,
        };
      },
      invalidatesTags: [{ type: "UserPosition", id: "new" }],
    }),
    deleteUserPosition: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/job-position/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "UserPosition", id: "new" }],
    }),
  }),
});

export const {
  useGetUserPositionQuery,
  useAddUserPositionMutation,
  useUpdateUserPositionMutation,
  useDeleteUserPositionMutation,
} = userPositionApiSlice;
