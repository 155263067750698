import React from "react";
import {
  Grid,
  Stack,
  Typography,
  TextField,
  FormControl,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";

function LeaveFormData() {
  const [error, setError] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});

  const handleTextChange = (field, value) => {};
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <Stack
        direction="column"
        spacing={0}
        sx={{
          alignItems: "center", // Center Stack content horizontally within Grid
          width: "100%",
        }}
      >
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "left",
            alignItems: "left",
            width: "100%",
            paddingBlock: "4px",
          }}
        >
          <Grid item xs={12} sm={10} md={3}>
            <Typography variant="h6" align="left">
              Employee Name :
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={6}>
            <FormControl
              variant="standard"
              sx={{ width: "100%", justifyContent: "center" }}
            >
              <TextField
                id="standard-position"
                type="text"
                variant="standard"
                onChange={(e) => handleTextChange("code_no", e.target.value)}
                sx={{
                  "& .MuiInput-root": {
                    borderBottom: "1px solid", // Fixed bold underline
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Stack>
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "left",
            alignItems: "left",
            width: "100%",
            paddingBlock: "4px",
          }}
        >
          <Grid item xs={12} sm={10} md={3}>
            <Typography variant="h6" align="left">
              Leave Type :
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={6}>
            <Stack direction={"row"} sx={{ width: "100%" }} spacing={0}>
              <Stack direction="column" sx={{ width: "100%" }} spacing={0}>
                <FormControl
                  variant="standard"
                  sx={{ width: "100%", justifyContent: "center" }}
                >
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Sick Leave"
                    sx={{ width: "100%" }}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Maternity Leave"
                    sx={{ width: "100%" }}
                  />
                </FormControl>
              </Stack>
              <Stack direction="column" sx={{ width: "100%" }} spacing={0}>
                <FormControl
                  variant="standard"
                  sx={{ width: "100%", justifyContent: "center" }}
                >
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Vacation Leave"
                    sx={{ width: "100%" }}
                  />

                  <FormControlLabel
                    control={<Checkbox />}
                    label="Special Leave"
                    sx={{ width: "100%" }}
                  />
                </FormControl>
              </Stack>
            </Stack>
          </Grid>
        </Stack>
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "left",
            alignItems: "left",
            width: "100%",
            paddingBlock: "4px",
          }}
        >
          <Grid item xs={12} sm={10} md={3}>
            <Typography variant="h6" align="left">
              Start Date :
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={6}>
            <FormControl
              variant="standard"
              sx={{ width: "100%", justifyContent: "center" }}
            >
              <TextField
                id="standard-position"
                type="date"
                variant="standard"
                onChange={(e) => handleTextChange("code_no", e.target.value)}
                sx={{
                  "& .MuiInput-root": {
                    borderBottom: "1px solid", // Fixed bold underline
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Stack>
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "left",
            alignItems: "left",
            width: "100%",
            paddingBlock: "4px",
          }}
        >
          <Grid item xs={12} sm={10} md={3}>
            <Typography variant="h6" align="left">
              End Date :
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={6}>
            <FormControl
              variant="standard"
              sx={{ width: "100%", justifyContent: "center" }}
            >
              <TextField
                id="standard-position"
                type="date"
                variant="standard"
                onChange={(e) => handleTextChange("code_no", e.target.value)}
                sx={{
                  "& .MuiInput-root": {
                    borderBottom: "1px solid", // Fixed bold underline
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Stack>
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "left",
            alignItems: "left",
            width: "100%",
            paddingBlock: "4px",
          }}
        >
          <Grid item xs={12} sm={10} md={3}>
            <Typography variant="h6" align="left">
              Leave duration :
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={6}>
            <FormControl
              variant="standard"
              sx={{ width: "100%", justifyContent: "center" }}
            >
              <Typography variant="h6" align="left">
                2 days
              </Typography>
              <Typography sx={{ color: "red" }}>
                Note: The duration is calculated based on the start and end date
              </Typography>
            </FormControl>
          </Grid>
        </Stack>
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "left",
            alignItems: "left",
            width: "100%",
            paddingBlock: "4px",
          }}
        >
          <Grid item xs={12} sm={10} md={3}>
            <Typography variant="h6" align="left">
              Leave Reason :
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={6}>
            <FormControl
              variant="standard"
              sx={{ width: "100%", justifyContent: "center" }}
            >
              <TextField
                id="standard-position"
                type="text"
                multiline
                variant="standard"
                onChange={(e) => handleTextChange("code_no", e.target.value)}
                sx={{
                  "& .MuiInput-root": {
                    borderBottom: "1px solid", // Fixed bold underline
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Stack>
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "left",
            alignItems: "left",
            width: "100%",
            paddingTop: "10px",
          }}
        >
          <Grid item xs={12} sm={12} md={12} sx={{ textAlign: "center" }}>
            <Button variant="contained" color="primary">
              Request Leave
            </Button>
          </Grid>
        </Stack>
      </Stack>
    </Grid>
  );
}

export default LeaveFormData;
