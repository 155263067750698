import { Paper, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ResponsiveList from "../../../components/list/ResponsiveList";
import { useEmployeeWidgetQuery } from "../../../app/api/reports/dashboardApiSlice";
import useAuth from "../../../hooks/useAuth";

function EmployeeWidget() {
  const { area_assign, assign_id, branch_no, dashboard } = useAuth();

  const { data, error, isLoading } = useEmployeeWidgetQuery(
    { ...(dashboard === true ? { branch_no: branch_no } : {}) },
    { refetchOnMountOrArgChange: true }
  );
  const [employeeData, setEmployeeData] = React.useState(
    {
      activeEmployee: 0,
      inactiveEmployee: 0,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const categories = [
    { title: "ACTIVE", value: 5 },
    { title: "IN-ACTIVE", value: 3 },
  ];
  useEffect(() => {
    if (data) {
      console.log(data);
      setEmployeeData({
        activeEmployee: data.activeEmployee,
        inactiveEmployee: data.inactiveEmployee,
      });
    }
  }, [data]);

  categories[0].value = (
    <a href="/manage-employees?status=0" style={{ textDecoration: "none" }}>
      {employeeData.activeEmployee}
    </a>
  );
  categories[1].value = (
    <a href="/manage-employees?status=1" style={{ textDecoration: "none" }}>
      {employeeData.inactiveEmployee}
    </a>
  );
  return (
    <Paper elevation={3} sx={{ p: 2, height: "100%" }}>
      <Stack spacing={2}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            textAlign: "center",
            backgroundColor: "#E1F7F5",
            color: "black",
          }}
        >
          EMPLOYEE SUMMARY
        </Typography>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <ResponsiveList categories={categories} />
        </Stack>
      </Stack>
    </Paper>
  );
}

export default EmployeeWidget;
