import {
  Button,
  Link,
  MenuItem,
  Paper,
  Select,
  Dialog,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ModalData } from "../../../components/modal/Modal";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useNavigate, useParams } from "react-router-dom";
import CsvImporterComponent from "../../../components/csvImport/CSVImport";
import { useGetDTRQuery } from "../../../app/api/users/usersApiSlice";
import dayjs from "dayjs";
import useAuth from "../../../hooks/useAuth";
import "./attendance.css";
import { useGenerateNewPayrollMutation } from "../../../app/api/payroll/generatePayroll";
import CustomizedMenus from "../../../components/buttons/CustomizeMenu";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PostAddIcon from "@mui/icons-material/PostAdd";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";

const getMuiTheme = () =>
  createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontWeight: 700,
            backgroundColor: "#f5f5f5",
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          h6: {
            fontWeight: 700,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: "pink",
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            "&.Mui-active": {
              fontWeight: "bold",
            },
            "& .MuiTableSortLabel-icon": {
              fontWeight: "bold",
            },
          },
        },
      },
    },
  });
// Helper function to parse time in HH:MM format
const parseTime = (time) => {
  const [hours, minutes] = time.split(":").map(Number);
  return { hours, minutes };
};

// Helper function to calculate time difference in hours
const calculateTimeDifference = (start, end) => {
  if (!start || !end) return 0;

  const { hours: startHours, minutes: startMinutes } = parseTime(start);
  const { hours: endHours, minutes: endMinutes } = parseTime(end);

  const startInMinutes = startHours * 60 + startMinutes;
  const endInMinutes = endHours * 60 + endMinutes;

  return (endInMinutes - startInMinutes) / 60;
};

function AttendanceData() {
  const [openModal, setOpenModal] = useState(false);
  const [bodyContent, setBodyContent] = useState("");
  const [modalType, setModalType] = useState("");
  const navigate = useNavigate();
  const { branch_no, id } = useAuth();
  const [filterDTR, setFilterDTR] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    period: 1,
    branch_id: branch_no,
    user_id: id,
  });

  const [employeeData, setEmployeeData] = useState([]);

  const currentYear = new Date().getFullYear();

  const generateYearOptions = (startYear, numberOfYears) => {
    const yearOptions = [];
    for (let i = 0; i < numberOfYears; i++) {
      yearOptions.push(startYear + i);
    }
    return yearOptions;
  };
  const yearOptions = generateYearOptions(currentYear, 5);

  const { data: dtr, isLoading: dtrLoading } = useGetDTRQuery(filterDTR, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (dtr && !dtrLoading) {
      processUsersData(dtr);
    }
  }, [dtr, dtrLoading]);

  const processUsersData = (users) => {
    let allDataArray = [];
    if (Array.isArray(users)) {
      allDataArray = users;
    } else {
      allDataArray = Object.entries(users.entities).map(
        ([key, value]) => value
      );
    }
    setEmployeeData(allDataArray);
  };
  /*employeeData.map((data) => ({
    id: data.user_id,
    lastname: data.last_name,
    firstname: data.first_name,
    middlename: data.middle_name,
    name: `${data.last_name}, ${data.first_name} ${data.middle_name}`,
    dtr: data.dtr,
  }));*/
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    const tableData = [];
    employeeData.map(async (data) => {
      const res = await processDTRArray(data.dtr, data.user_id);
      tableData.push({
        id: data.user_id,
        lastname: data.last_name,
        firstname: data.first_name,
        middlename: data.middle_name,
        name: `${data.last_name}, ${data.first_name} ${data.middle_name}`,
        total_hours_min: res[data.user_id].total_hours_minutes,
        days_work: res[data.user_id].days_work,
        total_hours: res[data.user_id].total_hours,
        total_minutes: res[data.user_id].total_minutes,
        benefits: JSON.stringify(data.combined_benefits),
        rate: data.rate,
      });
    });
    setTableData(tableData);
  }, [employeeData]);

  const processDTRArray = (dtr, uid) => {
    const dtrArray = {};
    dtr.forEach((data) => {
      const indexDate = dayjs(data.dtr_date).date();

      // Initialize the user's entry if it doesn't exist
      if (!dtrArray[data.user_id]) {
        dtrArray[data.user_id] = {};
      }

      // Initialize the date entry for the user if it doesn't exist
      if (!dtrArray[data.user_id][indexDate]) {
        dtrArray[data.user_id][indexDate] = {
          id: indexDate,
          total_hours: 0,
          minutes: 0,
          hours_am: 0,
          hours_pm: 0,
          total: 0,
        };
      }

      // Calculate hours based on the type (AM or PM)
      if (data.type === "AM") {
        dtrArray[data.user_id][indexDate].hours_am = calculateTimeDifference(
          data.time_in,
          data.time_out
        );
      } else if (data.type === "PM") {
        dtrArray[data.user_id][indexDate].hours_pm = calculateTimeDifference(
          data.time_in,
          data.time_out
        );
      }

      // Calculate total hours and minutes
      const totalHours =
        dtrArray[data.user_id][indexDate].hours_am +
        dtrArray[data.user_id][indexDate].hours_pm;
      const hours = Math.floor(totalHours);
      const minutes = Math.round((totalHours - hours) * 60);

      dtrArray[data.user_id][indexDate].total_hours = hours || 0;
      dtrArray[data.user_id][indexDate].minutes = minutes || 0;
      dtrArray[data.user_id][indexDate].total = `${hours}:${minutes}` || 0;
    });

    // Calculate total hours and minutes for all entries
    let totalHoursAll = 0;
    let totalMinutesAll = 0;

    Object.values(dtrArray).forEach((userEntry) => {
      Object.values(userEntry).forEach((dateEntry) => {
        totalHoursAll += dateEntry.total_hours;
        totalMinutesAll += dateEntry.minutes;
      });
    });

    // Convert total minutes to hours and minutes
    totalHoursAll += Math.floor(totalMinutesAll / 60);
    totalMinutesAll = totalMinutesAll % 60;

    console.log(
      `Total Hours: ${totalHoursAll}, Total Minutes: ${totalMinutesAll},user_id: ${uid}`
    );

    if (!dtrArray[uid]) dtrArray[uid] = {};
    if (!dtrArray[uid]["total_hours_minutes"])
      dtrArray[uid]["total_hours_minutes"] = `${totalHoursAll} hrs  ${
        totalMinutesAll ? "& " + totalMinutesAll + " mins" : ""
      } `;
    dtrArray[uid]["days_work"] = (
      Math.floor(((totalHoursAll + totalMinutesAll / 60) / 8) * 10) / 10
    ).toFixed(1);
    dtrArray[uid]["total_hours"] = totalHoursAll;
    dtrArray[uid]["total_minutes"] = totalMinutesAll;

    return dtrArray;
    //storeRecords.push(dtrArray);
  };
  //console.log(storeRecords);

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    setFilterDTR((prev) => ({ ...prev, month: selectedMonth }));
  };

  const handleYearChange = (event) => {
    const selectedYear = event.target.value;
    setFilterDTR((prev) => ({ ...prev, year: selectedYear }));
  };

  const handlePeriodChange = (event) => {
    const selectedPeriod = event.target.value;
    setFilterDTR((prev) => ({ ...prev, period: selectedPeriod }));
  };

  const [generatePayroll] = useGenerateNewPayrollMutation();

  const [loading, setLoading] = useState(false);

  const handleGeneratePayroll = async () => {
    setLoading(true);
    try {
      const formData = {
        month: filterDTR.month,
        year: filterDTR.year,
        period: filterDTR.period,
        branch_id: filterDTR.branch_id,
        user_id: filterDTR.user_id,
        payroll_data: tableData,
      };
      const res = await generatePayroll({ formData });
      if (res.data) {
        alert(res.data.message);
      }
    } catch (error) {
      console.error("Error generating payroll: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleImportEmployee = () => {
    setBodyContent(<CsvImporterComponent sType="importAttendance" />);
    setModalType("importEmployee");
    setOpenModal(true);
  };

  const handleCreateDTR = () => {
    navigate("/manage-attendance/add");
  };

  const handleViewDTR = (dataIndex, rowData) => {
    const selectedEmployee = rowData[dataIndex];
    const selectedEmployeeId = selectedEmployee.id;
    const selectedEmployeeName = `${selectedEmployee.lastname}, ${selectedEmployee.firstname} ${selectedEmployee.middlename}`;
    navigate(
      `/manage-attendance/EmployeeDTR?id=${selectedEmployeeId}&name=${selectedEmployeeName}&month=${filterDTR.month}&year=${filterDTR.year}&period=${filterDTR.period}`
    );
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const custMenuOption = [
    {
      label: "Import DTR",
      icon: <PostAddIcon />,
      action: handleImportEmployee,
    },
    {
      label: "Create DTR",
      icon: <GroupAddIcon />,
      action: handleCreateDTR,
    },
    {
      label: "Generate Payroll",
      icon: <RequestQuoteIcon />,
      action: handleGeneratePayroll,
    },
    {
      label: "Download DTR Format",
      icon: <SimCardDownloadIcon />,
      action: () => {
        window.open("/csv-template/dtr.csv", "_blank");
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    responsive: "standard",
    onRowsDelete: (rowsDeleted) => {
      console.log(rowsDeleted);
    },
    print: false,
    download: true,
    filter: false,
    selectableRowsHeader: true,
    selectableRows: true,
    setRowProps: (row, dataIndex) => {
      if (dataIndex === 0) {
        return {
          className: "MuiDataTable-FirstRow",
          style: { display: "none !important" },
        };
      }
      return {};
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      const getSelectedIds = () => {
        return selectedRows.data.map(
          (row) => displayData[row.dataIndex].data[0]
        ); // assuming the ID is the first column
      };
      return (
        <>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              console.log(getSelectedIds());
            }}
            sx={{ mr: 2 }}>
            Delete
          </Button>

          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              console.log(selectedRows);
            }}
            sx={{ mr: 2 }}>
            Generate Payroll
          </Button>

          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              const ids = getSelectedIds();
              window.location.href = `/manage-attendance/EmployeeDTR?id=${ids}&month=${filterDTR.month}&year=${filterDTR.year}&period=${filterDTR.period}`;
            }}
            sx={{ mr: 2 }}>
            Print DTR
          </Button>
        </>
      );
    },

    customToolbar: () => (
      <>
        <Select
          sx={{ height: "37px", mr: 2 }}
          value={filterDTR.month}
          onChange={handleMonthChange}
          displayEmpty
          inputProps={{ "aria-label": "Select Month" }}>
          <MenuItem value="" disabled>
            Select Month
          </MenuItem>
          <MenuItem value={1}>January</MenuItem>
          <MenuItem value={2}>February</MenuItem>
          <MenuItem value={3}>March</MenuItem>
          <MenuItem value={4}>April</MenuItem>
          <MenuItem value={5}>May</MenuItem>
          <MenuItem value={6}>June</MenuItem>
          <MenuItem value={7}>July</MenuItem>
          <MenuItem value={8}>August</MenuItem>
          <MenuItem value={9}>September</MenuItem>
          <MenuItem value={10}>October</MenuItem>
          <MenuItem value={11}>November</MenuItem>
          <MenuItem value={12}>December</MenuItem>
        </Select>

        <Select
          sx={{ height: "37px", mr: 2 }}
          value={filterDTR.year}
          onChange={handleYearChange}
          displayEmpty
          inputProps={{ "aria-label": "Select Year" }}>
          <MenuItem value="" disabled>
            Select Year
          </MenuItem>
          {yearOptions.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>

        <Select
          sx={{ height: "37px", mr: 2 }}
          value={filterDTR.period}
          onChange={handlePeriodChange}
          displayEmpty
          inputProps={{ "aria-label": "Select Year" }}>
          <MenuItem value="" disabled>
            Select Period
          </MenuItem>
          <MenuItem value={1}>1 - 15th</MenuItem>
          <MenuItem value={2}>16th - 30th</MenuItem>
        </Select>
        <CustomizedMenus options={custMenuOption} />
      </>
    ),
  };

  const columns = [
    {
      name: "id",
      label: "ID No.",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "total_hours_min",
      label: "Total hours (hrs:min)",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "days_work",
      label: "Days Work",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleViewDTR(dataIndex, tableData)}
                sx={{ mr: 2 }}>
                View DTR
              </Button>

              {/*<Button
                variant="contained"
                color="primary"
                onClick={() => handleViewDTR(dataIndex, tableData)}
              >
                Add Attendance
              </Button>*/}
            </>
          );
        },
      },
    },
  ];
  return (
    <>
      <Paper
        elevation={3}
        sx={{ p: 2, mt: 2, minHeight: "600px", backgroundColor: "#D1F3F1" }}>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={`DTR for ${filterDTR.month} / ${filterDTR.year} - Period ${
              filterDTR.period === 1 ? "1 - 15th" : "16th - 30th"
            }`}
            data={tableData}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
        {openModal && (
          <ModalData
            openModal={openModal}
            closeModal={handleCloseModal}
            bodyContent={bodyContent}
            type={modalType}
          />
        )}

        <Dialog open={loading}>
          <DialogContent>
            <CircularProgress />
            <p>Generating payroll, please wait...</p>
          </DialogContent>
        </Dialog>
      </Paper>
    </>
  );
}

export default AttendanceData;
