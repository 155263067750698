import {
  Box,
  Stack,
  Typography,
  TextField,
  Grid,
  Select,
  MenuItem,
  Button,
  Paper,
} from "@mui/material";
import React, { useState } from "react";
import "./personal.css";

function PersonalInformation(data) {
  const [error, setError] = useState(false);
  const handleChange = (field, value) => {
    data.setPersonalInformation((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Box
        component="form"
        validate="true"
        sx={{ mt: 1 }}
        className="memberFormData"
      >
        <Typography variant="h6" sx={{ fontWeight: 700 }} mb={2} gutterBottom>
          PERSONAL INFORMATION
        </Typography>
        <Grid container spacing={2} className="personal-info-grid">
          <Grid item xs={12} md={5}>
            <Stack direction={"column"} spacing={1}>
              <Stack direction={"row"} spacing={2}>
                <Typography
                  sx={{
                    width:
                      data.type === "edit"
                        ? { xs: "100%", sm: "178px" }
                        : { xs: "100%", sm: "150px" },
                  }}
                  className="personalInfoName"
                  variant="div"
                >
                  Name:
                </Typography>
                <Stack direction={"row"} spacing={2}>
                  {data.type === "add" || data.type === "edit" ? (
                    <>
                      <TextField
                        label="Firstname"
                        type="text"
                        style={{ width: "100%" }}
                        error={!!data.formErrors.firstName}
                        helperText={data.formErrors.firstName}
                        value={data.personalInformation.firstName}
                        onChange={(e) =>
                          handleChange("firstName", e.target.value)
                        }
                      />
                      <TextField
                        label="Middlename"
                        type="text"
                        style={{ width: "100%" }}
                        error={error}
                        value={data.personalInformation.middleName}
                        onChange={(e) =>
                          handleChange("middleName", e.target.value)
                        }
                      />
                      <TextField
                        label="Lastname"
                        type="text"
                        style={{ width: "100%" }}
                        error={!!data.formErrors.lastName}
                        helperText={data.formErrors.lastName}
                        value={data.personalInformation.lastName}
                        onChange={(e) =>
                          handleChange("lastName", e.target.value)
                        }
                      />
                    </>
                  ) : (
                    <Typography
                      variant="div"
                      sx={{ fontWeight: 700, textAlign: "center" }}
                    >
                      {data.personalInformation.firstName}{" "}
                      {data.personalInformation.middleName}{" "}
                      {data.personalInformation.lastName}
                    </Typography>
                  )}
                </Stack>
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <Typography sx={{ width: "150px" }} variant="div">
                  Address:
                </Typography>
                {data.type === "add" || data.type === "edit" ? (
                  <TextField
                    label="Address"
                    type="text"
                    style={{ width: "100%" }}
                    error={!!data.formErrors.address}
                    helperText={data.formErrors.address}
                    value={data.personalInformation.address}
                    onChange={(e) => handleChange("address", e.target.value)}
                  />
                ) : (
                  <Typography
                    variant="div"
                    sx={{ fontWeight: 700, textAlign: "center" }}
                  >
                    {data.personalInformation.address}
                  </Typography>
                )}
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <Typography sx={{ width: "150px" }} variant="div">
                  Nationality:
                </Typography>
                {data.type === "add" || data.type === "edit" ? (
                  <TextField
                    label="Nationality"
                    type="text"
                    style={{ width: "100%" }}
                    value={data.personalInformation.nationality}
                    onChange={(e) =>
                      handleChange("nationality", e.target.value)
                    }
                  />
                ) : (
                  <Typography
                    variant="div"
                    sx={{ fontWeight: 700, textAlign: "center" }}
                  >
                    {data.personalInformation.nationality}
                  </Typography>
                )}
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <Typography sx={{ width: "150px" }} variant="div">
                  Father's Name:
                </Typography>
                {data.type === "add" || data.type === "edit" ? (
                  <TextField
                    label="Father's Name"
                    type="text"
                    style={{ width: "100%" }}
                    error={error}
                    value={data.personalInformation.fathersName}
                    onChange={(e) =>
                      handleChange("fathersName", e.target.value)
                    }
                    helperText={error ? "Please input father's name" : ""}
                  />
                ) : (
                  <Typography
                    variant="div"
                    sx={{ fontWeight: 700, textAlign: "center" }}
                  >
                    {data.personalInformation.fathersName}
                  </Typography>
                )}
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <Typography sx={{ width: "150px" }} variant="div">
                  Mother's Name:
                </Typography>
                {data.type === "add" || data.type === "edit" ? (
                  <TextField
                    label="Mother's name"
                    type="text"
                    style={{ width: "100%" }}
                    error={error}
                    value={data.personalInformation.mothersName}
                    onChange={(e) =>
                      handleChange("mothersName", e.target.value)
                    }
                    helperText={error ? "Please input mothers name" : ""}
                  />
                ) : (
                  <Typography
                    variant="div"
                    sx={{ fontWeight: 700, textAlign: "center" }}
                  >
                    {data.personalInformation.mothersName}
                  </Typography>
                )}
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <Typography sx={{ width: "150px" }} variant="div">
                  Mobile No:
                </Typography>
                {data.type === "add" || data.type === "edit" ? (
                  <TextField
                    label="Phone Number"
                    type="number"
                    style={{ width: "100%" }}
                    error={!!data.formErrors.mobileNo}
                    value={data.personalInformation.mobileNo}
                    onChange={(e) => handleChange("mobileNo", e.target.value)}
                    helperText={data.formErrors.mobileNo}
                  />
                ) : (
                  <Typography
                    variant="div"
                    sx={{ fontWeight: 700, textAlign: "center" }}
                  >
                    {data.personalInformation.mobileNo}
                  </Typography>
                )}
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <Typography sx={{ width: "150px" }} variant="div">
                  Height:
                </Typography>
                {data.type === "add" || data.type === "edit" ? (
                  <TextField
                    label="height"
                    type="text"
                    style={{ width: "100%" }}
                    error={!!data.formErrors.height}
                    value={data.personalInformation.height}
                    onChange={(e) => handleChange("height", e.target.value)}
                    helperText={data.formErrors.height}
                  />
                ) : (
                  <Typography
                    variant="div"
                    sx={{ fontWeight: 700, textAlign: "center" }}
                  >
                    {data.personalInformation.height}
                  </Typography>
                )}
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <Typography sx={{ width: "150px" }} variant="div">
                  Date of Birth:
                </Typography>
                {data.type === "add" || data.type === "edit" ? (
                  <TextField
                    label=""
                    type="date"
                    style={{ width: "100%" }}
                    error={!!data.formErrors.dateOfBirth}
                    value={data.personalInformation.dateOfBirth}
                    onChange={(e) =>
                      handleChange("dateOfBirth", e.target.value)
                    }
                    helperText={data.formErrors.dateOfBirth}
                  />
                ) : (
                  <Typography
                    variant="div"
                    sx={{ fontWeight: 700, textAlign: "center" }}
                  >
                    {data.personalInformation.dateOfBirth}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={5}>
            <Stack direction={"column"} spacing={1}>
              <Stack direction={"row"} spacing={2}>
                <Typography sx={{ width: "150px" }} variant="div">
                  Gender:
                </Typography>
                {data.type === "add" || data.type === "edit" ? (
                  <Select
                    label="gender"
                    style={{ width: "100%" }}
                    error={!!data.formErrors.gender}
                    defaultValue={"Male"}
                    value={data.personalInformation.gender}
                    onChange={(e) => handleChange("gender", e.target.value)}
                    helperText={data.formErrors.gender}
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                  </Select>
                ) : (
                  <Typography
                    variant="div"
                    sx={{ fontWeight: 700, textAlign: "center" }}
                  >
                    {data.personalInformation.gender}
                  </Typography>
                )}
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <Typography sx={{ width: "150px" }} variant="div">
                  Religion:
                </Typography>
                {data.type === "add" || data.type === "edit" ? (
                  <TextField
                    label="Religion"
                    type="text"
                    style={{ width: "100%" }}
                    error={error}
                    value={data.personalInformation.religion}
                    onChange={(e) => handleChange("religion", e.target.value)}
                    helperText={error ? "Please input religion" : ""}
                  />
                ) : (
                  <Typography
                    variant="div"
                    sx={{ fontWeight: 700, textAlign: "center" }}
                  >
                    {data.personalInformation.religion}
                  </Typography>
                )}
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <Typography sx={{ width: "150px" }} variant="div">
                  Languages:
                </Typography>
                {data.type === "add" || data.type === "edit" ? (
                  <TextField
                    label="Languages"
                    type="text"
                    style={{ width: "100%" }}
                    error={error}
                    value={data.personalInformation.languages}
                    onChange={(e) => handleChange("languages", e.target.value)}
                    helperText={error ? "Please input languages" : ""}
                  />
                ) : (
                  <Typography
                    variant="div"
                    sx={{ fontWeight: 700, textAlign: "center" }}
                  >
                    {data.personalInformation.languages}
                  </Typography>
                )}
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <Typography sx={{ width: "150px" }} variant="div">
                  FB Account:
                </Typography>
                {data.type === "add" || data.type === "edit" ? (
                  <TextField
                    label="FB Account"
                    type="text"
                    style={{ width: "100%" }}
                    error={error}
                    value={data.personalInformation.fbAccount}
                    onChange={(e) => handleChange("fbAccount", e.target.value)}
                    helperText={error ? "Please input FB account link" : ""}
                  />
                ) : (
                  <Typography
                    variant="div"
                    sx={{ fontWeight: 700, textAlign: "center" }}
                  >
                    {data.personalInformation.fbAccount}
                  </Typography>
                )}
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <Typography sx={{ width: "150px" }} variant="div">
                  Email:
                </Typography>
                {data.type === "add" || data.type === "edit" ? (
                  <TextField
                    label="Email Address"
                    type="email"
                    style={{ width: "100%" }}
                    error={!!data.formErrors.email}
                    value={data.personalInformation.email}
                    onChange={(e) => handleChange("email", e.target.value)}
                    helperText={data.formErrors.email}
                  />
                ) : (
                  <Typography
                    variant="div"
                    sx={{ fontWeight: 700, textAlign: "center" }}
                  >
                    {data.personalInformation.email}
                  </Typography>
                )}
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <Typography sx={{ width: "150px" }} variant="div">
                  SG License:
                </Typography>
                {data.type === "add" || data.type === "edit" ? (
                  <TextField
                    label="SG License No"
                    type="text"
                    style={{ width: "100%" }}
                    error={!!data.formErrors.liscenseNo}
                    value={data.personalInformation.liscenseNo}
                    onChange={(e) => handleChange("liscenseNo", e.target.value)}
                    helperText={data.formErrors.liscenseNo}
                  />
                ) : (
                  <Typography
                    variant="div"
                    sx={{ fontWeight: 700, textAlign: "center" }}
                  >
                    {data.personalInformation.liscenseNo}
                  </Typography>
                )}
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <Typography sx={{ width: "150px" }} variant="div">
                  Expiry Date:
                </Typography>
                {data.type === "add" || data.type === "edit" ? (
                  <TextField
                    label=""
                    type="date"
                    style={{ width: "100%" }}
                    error={!!data.formErrors.expiryDate}
                    value={data.personalInformation.expiryDate}
                    onChange={(e) => handleChange("expiryDate", e.target.value)}
                    helperText={data.formErrors.expiryDate}
                  />
                ) : (
                  <Typography
                    variant="div"
                    sx={{ fontWeight: 700, textAlign: "center" }}
                  >
                    {data.personalInformation.expiryDate}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={2} md={2} className="move-to-first-column">
            <Box sx={{ width: "100%", height: "150px" }}>
              {data.imageData ? (
                <img
                  src={data.imageData}
                  alt="image"
                  style={{ width: "100%", height: "150px" }}
                />
              ) : (
                ""
              )}
            </Box>
            {data.type === "add" || data.type === "edit" ? (
              <Button
                color="primary"
                variant="contained"
                sx={{ width: "100%", marginTop: "10px" }}
                onClick={data.handleTakePhoto}
              >
                Take Photo
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

export default PersonalInformation;
