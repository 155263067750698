import * as React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Paper,
  Divider,
} from "@mui/material";
import Box from "@mui/material/Box";

export default function ResponsiveList({ categories, type }) {
  let content = "";

  if (categories) {
    let gridmd = 12;
    if (type !== 1) {
      gridmd = 12 / categories.length;
    }

    content = categories.map((category) => (
      <Grid item xs={12} sm={6} md={gridmd} key={category.title}>
        <Card sx={{ height: "100%", backgroundColor: "#D1F0EF" }}>
          <CardContent>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "15px",
                textAlign: "center",
                height: "60px",
              }}
              component="div">
              {category.title}
            </Typography>

            <Typography
              variant="body2"
              sx={{
                fontWeight: 700,
                textAlign: "center",
                fontSize: "20px",
              }}
              color="text.secondary">
              {category.value}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    ));
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {content}
      </Grid>
    </Box>
  );
}
