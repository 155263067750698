import {
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Autocomplete,
  Box,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import PersonalInformation from "./employeeForms/PersonalInformation";
import EducationalBackground from "./employeeForms/EducationalBackground";
import { ModalData } from "../../../components/modal/Modal";
import Camera from "../../../components/camera/Camera";
import WorkExperience from "./employeeForms/WorkExperience";
import EmployeeAssignment from "./employeeForms/EmployeeAssignment";
import DocumentAttachments from "./employeeForms/DocumentAttachments";
import BenefitsInformaton from "./employeeForms/BenefitsInformaton";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import {
  useAddUserMutation,
  useGetUsersQuery,
  useGetListEmployeeQuery,
} from "../../../app/api/users/usersApiSlice";
import useAuth from "../../../hooks/useAuth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import dayjs from "dayjs";
import { useGetUserPositionQuery } from "../../../app/api/users/userPosition";
import { useReactToPrint } from "react-to-print";

const position = [];

function EmployeeAdd() {
  const [formErrors, setFormErrors] = useState({});
  const { id, type, isSuperAdmin } = useParams();
  const { branch_no, id: user_log_id, local_user_id } = useAuth();
  const [openModal, setOpenModal] = React.useState(false);
  const [imageData, setImageData] = React.useState(null);
  const [documentUploaded, setDocumentUploaded] = React.useState([]);
  const [employeeOptions, setEmployeeOptions] = React.useState(null);
  const [selectedValue, setSelectedValue] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [employeeName, setEmployeeName] = React.useState(null);
  const [searchEmployee, setSearchEmployee] = React.useState(null);
  const [modalType, setModalType] = React.useState("");

  const [addEmployee, { isLoading }] = useAddUserMutation();
  const [editMode, setEditMode] = useState(false);

  const { data: employeeData } = useGetListEmployeeQuery(
    {
      ...(isSuperAdmin && { branch_id: branch_no }),
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: employeeDataReq, refetch } = useGetUsersQuery(
    {
      id: id,
      ...(isSuperAdmin && { branch_id: branch_no }),
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: positionData } = useGetUserPositionQuery(
    {
      ...(isSuperAdmin && { branch_id: branch_no }),
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (positionData) {
      positionData.map((pos) => {
        position.push({ label: pos.position_name, value: pos.id });
      });
    }
  }, [positionData]);

  useEffect(() => {
    if (employeeData) {
      const list = employeeData.map((item) => {
        return {
          label: `${item.personal_info.last_name}, ${item.personal_info.first_name} ${item.personal_info.middle_name}`,
          value: item.user_id,
        };
      });
      setEmployeeOptions(list);
    }
  }, [employeeData]);

  const navigate = useNavigate();
  const [employeeAssignment, setEmployeeAssignment] = useState({
    position: "",
    assignment: "",
    remarks: "",
    dateHired: "",
    dateEndContract: "",
    remarksEndContract: "",
    trainingCompletion: "",
    hoursCompleted: "",
    duration: "",
    workStatus: "",
  });
  const [personalInformation, setPersonalInformation] = useState({
    expiryDate: "",
    liscenseNo: "",
    email: "",
    fbAccount: "",
    languages: "",
    religion: "",
    gender: "",
    dateOfBirth: "",
    height: "",
    mobileNo: "",
    mothersName: "",
    fathersName: "",
    nationality: "",
    address: "",
    lastName: "",
    middleName: "",
    firstName: "",
  });
  const [educationalBackground, setEducationalBackground] = useState({
    elementary: {
      school_name: "",
      course: "",
      year_graduated: "",
      highest_level: "",
    },
    high_school: {
      school_name: "",
      course: "",
      year_graduated: "",
      highest_level: "",
    },
    college: {
      school_name: "",
      course: "",
      year_graduated: "",
      highest_level: "",
    },
    others: {
      school_name: "",
      course: "",
      year_graduated: "",
      highest_level: "",
    },
  });

  const [benefitsInformation, setBenefitsInformation] = useState({
    sss: "",
    pagibig: "",
    philhealth: "",
  });
  const [workExperiences, setWorkExperiences] = useState([
    { companyName: "", position: "", workPeriod: "", work_id: "" },
  ]);

  useEffect(() => {
    if (employeeDataReq) {
      const data = employeeDataReq[0];

      if (data) {
        console.log(data);
        setPersonalInformation({
          expiryDate: dayjs(data.expiryDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
          liscenseNo: data.liscenseNo,
          email: data.email,
          fbAccount: data.fbAccount,
          languages: data.languages,
          religion: data.religion,
          gender: data.gender,

          dateOfBirth: dayjs(data.birthdate, "YYYY-MM-DD").format("YYYY-MM-DD"),
          height: data.height,
          mobileNo: data.phoneNumber,
          mothersName: data.mothersName,
          fathersName: data.fathersName,

          nationality: data.nationality,
          address: data.address,
          lastName: data.last_name,
          middleName: data.middle_name,
          firstName: data.first_name,
        });
        setEmployeeAssignment({
          position: position.find((pos) => pos.label === data?.position).value,
          assignment: data.assign_id,
          remarks: data.remarks,
          dateHired: dayjs(data.dateHired, "YYYY-MM-DD").format("YYYY-MM-DD"),
          dateEndContract: dayjs(data.dateEndContract, "YYYY-MM-DD").format(
            "YYYY-MM-DD"
          ),
          remarksEndContract: data.remarks_end_of_contract,
          trainingCompletion: dayjs(
            data.trainingCompletion,
            "YYYY-MM-DD"
          ).format("YYYY-MM-DD"),
          hoursCompleted: data.hours_of_training_completed,
          duration: data.duration,
          workStatus: data.work_status,
        });

        if (data.user_education !== "") {
          data.user_education.map((education) => {
            if (education.education_level === "elementary") {
              setEducationalBackground((prev) => ({
                ...prev,
                elementary: {
                  school_name: education.school_name,
                  year_graduated: education.year_of_graduation,
                  highest_level: education.highest_level,
                },
              }));
            } else if (education.education_level === "high_school") {
              setEducationalBackground((prev) => ({
                ...prev,
                high_school: {
                  school_name: education.school_name,
                  year_graduated: education.year_of_graduation,
                  highest_level: education.highest_level,
                },
              }));
            } else if (education.education_level === "college") {
              setEducationalBackground((prev) => ({
                ...prev,
                college: {
                  school_name: education.school_name,
                  course: education.course,
                  year_graduated: education.year_of_graduation,
                  highest_level: education.highest_level,
                },
              }));
            } else {
              setEducationalBackground((prev) => ({
                ...prev,
                others: {
                  school_name: education.school_name,
                  course: education.course,
                  year_graduated: education.year_of_graduation,
                  highest_level: education.highest_level,
                },
              }));
            }
          });
        }

        if (data.user_work_experience !== "") {
          setWorkExperiences([]);
          const workExp = data.user_work_experience.map((work) => {
            return {
              companyName: work.company_name,
              position: work.position,
              workPeriod: work.work_period,
              work_id: work.id,
            };
          });

          setWorkExperiences(workExp);
        }

        if (data.user_benefits !== "") {
          data.user_benefits.map((benefit) => {
            setBenefitsInformation((prev) => ({
              ...prev,
              [benefit.benefits_name]: benefit.benefits_no,
            }));
          });
        }
        //setImageData(data.profile_picture);
      }
    }
  }, [employeeDataReq, editMode]);

  const handleTakePhoto = () => {
    setModalType("takePhoto");
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const validateForm = () => {
    const errors = {};
    if (!personalInformation.firstName) {
      errors.firstName = "First Name is required";
    }
    if (!personalInformation.lastName) {
      errors.lastName = "Last Name is required";
    }
    if (!personalInformation.dateOfBirth) {
      errors.dateOfBirth = "Date of Birth is required";
    }
    if (!personalInformation.address) {
      errors.address = "Address is required";
    }

    if (!personalInformation.mobileNo) {
      errors.mobileNo = "Mobile Number is required";
    }

    if (!personalInformation.email) {
      errors.email = "Email is required";
    }

    if (!personalInformation.liscenseNo) {
      errors.liscenseNo = "License Number is required";
    }
    if (!personalInformation.expiryDate) {
      errors.expiryDate = "Expiry Date is required";
    }

    if (!personalInformation.gender) {
      errors.gender = "Gender is required";
    }

    if (!employeeAssignment.position) {
      errors.position = "Position is required";
    }
    if (!employeeAssignment.assignment) {
      errors.assignment = "Assignment is required";
    }
    if (!employeeAssignment.dateHired) {
      errors.dateHired = "Date Hired is required";
    }
    if (!employeeAssignment.dateEndContract) {
      errors.dateEndContract = "End of Contract is required";
    }
    if (!employeeAssignment.workStatus) {
      errors.workStatus = "Work Status is required";
    }
    if (!employeeAssignment.duration) {
      errors.duration = "Duration is required";
    }

    if (!benefitsInformation.sss) {
      errors.sss = "SSS is required";
    }

    if (!benefitsInformation.pagibig) {
      errors.pagibig = "Pagibig is required";
    }

    if (!benefitsInformation.philhealth) {
      errors.philhealth = "Philhealth is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    try {
      if (!validateForm()) return;

      const formData = new FormData();
      formData.append("police_clerance", documentUploaded.police ?? "");
      formData.append("medical_certificate", documentUploaded.medical ?? "");
      formData.append("diploma", documentUploaded.diploma ?? "");
      formData.append("biodata", documentUploaded.biodata ?? "");
      formData.append("philhealth", documentUploaded.philhealth ?? "");
      formData.append("profile_picture", imageData ?? "");
      formData.append(
        "personalInformation",
        JSON.stringify(personalInformation)
      );
      formData.append(
        "educationalBackground",
        JSON.stringify(educationalBackground)
      );
      formData.append("workExperiences", JSON.stringify(workExperiences));
      formData.append("employeeAssignment", JSON.stringify(employeeAssignment));
      formData.append(
        "benefitsInformation",
        JSON.stringify(benefitsInformation)
      );
      formData.append("branch_id", branch_no);
      formData.append("added_by", local_user_id);
      formData.append("type", type);
      formData.append("edit_user_id", id);
      formData.append("user_image", imageData ?? "");
      const res = await addEmployee(formData);
      if (res) {
        toast.success(res.data.message);
        navigate("/manage-employees/view/" + res.data.user_id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleNextEmployee = () => {
    const nextId = parseInt(id) + 1;
    navigate(`/manage-employees/view/${nextId}`);
  };

  const handlePreviousEmployee = () => {
    if (parseInt(id) === 1) return;

    const previousId = parseInt(id) - 1;
    navigate(`/manage-employees/view/${previousId}`);
  };

  const handleEdit = () => {
    setEditMode((prev) => !prev); // Toggle edit mode to trigger useEffect
    navigate(`/manage-employees/edit/${id}`);
  };

  const validateSearch = () => {
    if (!selectedValue) {
      setError(true);
      setSearchEmployee(false);
      return false;
    }

    setError(false);
    setSearchEmployee(true);
    return true;
  };

  const handleSearchEmployee = () => {
    if (!validateSearch()) return;
    navigate(`/manage-employees/view/${selectedValue.value}`);
  };

  const handleCancelBtn = () => {
    navigate(`/manage-employees/view/${id}`);
  };

  const uniqueEmployeeOptions = employeeOptions?.filter(
    (option) => option.value !== id
  );

  const componentRef = useRef();
  const styleRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Application Form Print",
    onBeforeGetContent: () => {
      // Dynamically create and add the print styles
      const styleElement = document.createElement("style");
      styleElement.innerHTML = `
        @media print {
          @page {
            size: 8.5in 13in !important;
            margin: 8.13mm 13.46mm !important; /*Narrow*/
            width: 100%; 
          }
          .move-to-first-column {
            order: -1;
            position: absolute;
            right: 130px;
            width: 100%;
            height: 100%;
          }
            .move-to-first-column img {
              width: 100%;
              height: 150px;
              min-width: 200px;
            }

          .personalInfoName{
            width: 150px !important;
            
          }
        
        }
      `;
      document.head.appendChild(styleElement);
      styleRef.current = styleElement; // Store reference to the style element
    },
    onAfterPrint: () => {
      // Remove the dynamically added style after printing
      if (styleRef.current) {
        document.head.removeChild(styleRef.current);
      }
    },
  });
  return (
    <>
      {(type === "edit" || type === "view") && (
        <>
          <Paper sx={{ p: 2, mb: 2, textAlign: "left" }} elevation={3}>
            <Stack direction={{ xs: "column" }} spacing={2}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  onClick={() => navigate("/manage-employees")}
                  sx={{ width: { xs: "100%", sm: "auto" } }}
                >
                  Back
                </Button>
                <Box
                  sx={{
                    width: { xs: "100%", sm: "auto" },
                    mt: { xs: 1, sm: 0 },
                  }}
                >
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={1}
                    sx={{ width: "100%" }}
                  >
                    {type === "edit" ? (
                      <>
                        <LoadingButton
                          color="secondary"
                          loading={isLoading}
                          loadingPosition="start"
                          startIcon={<SaveIcon />}
                          variant="contained"
                          onClick={handleSubmit}
                          type="submit"
                          sx={{
                            fontSize: "1rem",
                            width: { xs: "100%", sm: "auto" },
                          }}
                        >
                          <span>{id ? "Update" : "Save"}</span>
                        </LoadingButton>

                        <Button
                          variant="contained"
                          sx={{ width: { xs: "100%", sm: "auto" } }}
                          onClick={handleCancelBtn}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          onClick={handleEdit}
                          sx={{ width: { xs: "100%", sm: "auto" } }}
                        >
                          Edit
                        </Button>

                        <Button
                          variant="contained"
                          sx={{ width: { xs: "100%", sm: "auto" } }}
                          onClick={handlePrint}
                        >
                          Print
                        </Button>
                      </>
                    )}
                  </Stack>
                </Box>
              </Stack>

              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={1}
                alignItems="center"
              >
                <Autocomplete
                  options={uniqueEmployeeOptions}
                  getOptionLabel={(option) => option.label} // Display the label
                  value={employeeName}
                  className="employeeName"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setEmployeeName(newValue);
                      setSelectedValue(newValue);
                    } else {
                      setEmployeeName(null);
                    }
                  }}
                  sx={{
                    "& .MuiAutocomplete-clearIndicator": { display: "none" },
                  }}
                  required
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      sx={{
                        textAlign: "center",
                        fontWeight: 700,
                        width: { xs: "100%", sm: "230px" }, // Full width on small screens
                        minWidth: "320px", // Full width on small screens
                      }}
                      label="Search Employee Name"
                      fullWidth
                      type="search"
                      placeholder="Search Employee Name"
                      required
                      className="employeeName"
                      error={error}
                      helperText={error ? "Please select an employee name" : ""}
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.label === String(value)
                  }
                />
                <Button
                  variant="contained"
                  onClick={handleSearchEmployee}
                  color="primary"
                  sx={{
                    width: { xs: "100%", sm: "auto" }, // Full width on small screens
                  }}
                >
                  Search
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </>
      )}
      <Box ref={componentRef} className="print-content">
        <PersonalInformation
          handleTakePhoto={handleTakePhoto}
          imageData={imageData}
          setPersonalInformation={setPersonalInformation}
          personalInformation={personalInformation}
          type={type}
          formErrors={formErrors}
        />
        <EducationalBackground
          setEducationalBackground={setEducationalBackground}
          educationalBackground={educationalBackground}
          type={type}
        />
        <WorkExperience
          setWorkExperiences={setWorkExperiences}
          workExperiences={workExperiences}
          type={type}
        />
        <EmployeeAssignment
          setEmployeeAssignment={setEmployeeAssignment}
          employeeAssignment={employeeAssignment}
          type={type}
          formErrors={formErrors}
        />
        {type === "add" || type === "edit" ? (
          <DocumentAttachments setDocumentUploaded={setDocumentUploaded} />
        ) : (
          ""
        )}
        <BenefitsInformaton
          setDocumentUploaded={setDocumentUploaded}
          setBenefitsInformation={setBenefitsInformation}
          benefitsInformation={benefitsInformation}
          type={type}
          formErrors={formErrors}
        />
      </Box>

      <Paper sx={{ p: 2, mt: 2, textAlign: "center" }} elevation={3}>
        {type === "view" ? (
          ""
        ) : (
          <LoadingButton
            color="secondary"
            loading={isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            onClick={handleSubmit}
            type="submit"
            sx={{ fontSize: "1rem" }}
          >
            <span>{id ? "Update" : "Save"}</span>
          </LoadingButton>
        )}
      </Paper>
      <ToastContainer />
      {openModal && (
        <ModalData
          openModal={openModal}
          closeModal={handleCloseModal}
          bodyContent={
            <Camera onClose={handleCloseModal} onConfirm={setImageData} />
          }
          type={"takePhoto"}
        />
      )}
    </>
  );
}

export default EmployeeAdd;
