const fields = [
  {
    label: "Employee ID",
    key: "employee_id",
    alternateMatches: ["EMPLOYEE ID", "id", "emp_id", "employee_no"],
    fieldType: {
      type: "input",
    },
    example: "12345",
  },
  {
    label: "Position",
    key: "position",
    alternateMatches: ["position", "POSITION"],
    fieldType: {
      type: "input",
    },
    example: "Security Guard",
    validations: [
      {
        rule: "required",
        errorMessage: "Position is required",
        level: "error",
      },
    ],
  },
  {
    label: "First Name",
    key: "first_name",
    alternateMatches: ["first_name", "FIRSTNAME"],
    fieldType: {
      type: "input",
    },
    example: "John",

    validations: [
      {
        rule: "required",
        errorMessage: "First Name is required",
        level: "error",
      },
    ],
  },
  {
    label: "Last Name",
    key: "last_name",
    alternateMatches: ["last_name", "LASTNAME"],
    fieldType: {
      type: "input",
    },
    example: "Doe",

    validations: [
      {
        rule: "required",
        errorMessage: "Last Name is required",
        level: "error",
      },
    ],
  },
  {
    label: "Middle Name",
    key: "middle_name",
    alternateMatches: ["middle_name", "MIDDLENAME"],
    fieldType: {
      type: "input",
    },
    example: "A",

    validations: [
      {
        rule: "required",
        errorMessage: "Middle Name is required",
        level: "error",
      },
    ],
  },
  {
    label: "Email",
    key: "email",
    alternateMatches: ["email", "EMAIL"],
    fieldType: {
      type: "input",
    },
    example: "johndoe@gmail.com",
  },
  {
    label: "Address",
    key: "address",
    alternateMatches: ["address", "ADDRESS"],
    fieldType: {
      type: "input",
    },
    example: "1234 Street",
  },
  {
    label: "Street",
    key: "street",
    alternateMatches: ["street", "STREET"],
    fieldType: {
      type: "input",
    },
    example: "1234 Street",
  },
  {
    label: "Barangay",
    key: "barangay",
    alternateMatches: ["barangay", "BARANGAY"],
    fieldType: {
      type: "input",
    },
    example: "1234 Street",
  },
  {
    label: "City",
    key: "city",
    alternateMatches: ["city", "CITY"],
    fieldType: {
      type: "input",
    },
    example: "1234 Street",
  },
  {
    label: "Province",
    key: "province",
    alternateMatches: [
      "province",
      "municipality",
      "state",
      "MUNICIPALITY",
      "STATE",
    ],
    fieldType: {
      type: "input",
    },
    example: "1234 Street",
  },
  {
    label: "Zip Code",
    key: "zip_code",
    alternateMatches: ["zip_code", "ZIPCODE"],
    fieldType: {
      type: "input",
    },
    example: "1234 Street",
  },
  {
    label: "Contact Number",
    key: "contact_number",
    alternateMatches: [
      "contact_number",
      "contact_no",
      "PHONE NUMBER",
      "PHONE NO",
    ],
    fieldType: {
      type: "input",
    },
    example: "09123456789",
  },
  {
    label: "Date of Birth",
    key: "date_of_birth",
    alternateMatches: ["DATE OF BIRTH", "dob"],
    fieldType: {
      type: "input",
    },
    example: "2021-12-31",
  },
  {
    label: "Religion",
    key: "religion",
    alternateMatches: ["RELIGION"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Fathers Name",
    key: "fathers_name",
    alternateMatches: ["FATHERS NAME"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Mothers Name",
    key: "mothers_name",
    alternateMatches: ["MOTHERS NAME"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Nationality",
    key: "nationality",
    alternateMatches: ["NATIONALITY"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Height",
    key: "height",
    alternateMatches: ["height", "HEIGHT"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Gender",
    key: "gender",
    alternateMatches: ["GENDER"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Language",
    key: "language",
    alternateMatches: ["LANGUAGE"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "FB Account",
    key: "fb_account",
    alternateMatches: ["FB ACCOUNT"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Email",
    key: "email",
    alternateMatches: ["EMAIL"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "SG License No.",
    key: "sg_license_no",
    alternateMatches: ["SG LICENSE NO."],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Expiry Date",
    key: "expiry_date",
    alternateMatches: ["EXPIRY DATE"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "SSS",
    key: "sss",
    alternateMatches: ["SSS"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "SSS Amount",
    key: "sss_amount",
    alternateMatches: ["SSS AMOUNT"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "PhilHealth",
    key: "philhealth",
    alternateMatches: ["PHILHEALTH"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "PhilHealth Amount",
    key: "philhealth_amount",
    alternateMatches: ["PHILHEALTH AMOUNT"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "PagIbig",
    key: "pagibig",
    alternateMatches: ["PAGIBIG"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "PagIbig Amount",
    key: "pagibig_amount",
    alternateMatches: ["PAGIBIG AMOUNT"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Elementary",
    key: "elementary_school",
    alternateMatches: ["ELEMENTARY"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Elementary Year Graduated",
    key: "elementary_year_graduated",
    alternateMatches: ["ELEMENTARY YEAR GRADUATED"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "High School",
    key: "hs_school",
    alternateMatches: ["HS SCHOOL"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "High School Year",
    key: "high_school_year_graduated",
    alternateMatches: ["HS YEAR GRADUATED"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "College School",
    key: "college_school",
    alternateMatches: ["COLLEGE SCHOOL"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "College School Year Graduated",
    key: "college_school_year_graduated",
    alternateMatches: ["COLLEGE SCHOOL YEAR GRADUATED"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Course",
    key: "course",
    alternateMatches: ["COURSE"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Course Year",
    key: "course_year",
    alternateMatches: ["COURSE YEAR"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Remarks",
    key: "remarks",
    alternateMatches: ["REMARKS"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Character Reference 1",
    key: "character_reference_1",
    alternateMatches: ["CHARACTER REF 1 (NAME)"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Character Reference 1 Contact",
    key: "character_reference_contact_1",
    alternateMatches: ["CONTACT #1"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Character Reference Position",
    key: "character_reference_position_1",
    alternateMatches: ["CHAR REF 1 POSITION"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Company Name 1",
    key: "company_name_1",
    alternateMatches: ["COMPANY NAME 1"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Character Reference 2",
    key: "character_reference_2",
    alternateMatches: ["CHARACTER REF 2 (NAME)"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Character Reference 2 Contact",
    key: "character_reference_contact_2",
    alternateMatches: ["CONTACT #2"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Character Reference 2 Position",
    key: "character_reference_position_2",
    alternateMatches: ["CHAR REF 2 POSITION"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Company Name 2",
    key: "company_name_2",
    alternateMatches: ["COMPANY NAME 2"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Character Reference 3",
    key: "character_reference_3",
    alternateMatches: ["CHARACTER REF 3 (NAME)"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Character Reference 3 Contact",
    key: "character_reference_contact_3",
    alternateMatches: ["CONTACT #3"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Character Reference 3 Position",
    key: "character_reference_position_3",
    alternateMatches: ["CHAR REF 3 POSITION"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Company Name 3",
    key: "company_name_3",
    alternateMatches: ["COMPANY NAME 3"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Date Hired",
    key: "date_hired",
    alternateMatches: ["DATE HIRED"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Assignment",
    key: "assignment",
    alternateMatches: ["ASSIGNMENT"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: " Assigment Address",
    key: "assignment_address",
    alternateMatches: ["ASSIGNMENT ADDRESS"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "date_end_of_contract",
    key: "date_end_of_contract",
    alternateMatches: ["DATE CONTRACT END"],
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Work Status",
    key: "work_status",
    alternateMatches: ["WORK STATUS"],
    fieldType: {
      type: "input",
    },
  },
];

export default fields;
