import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddBranch from "./AddBranch";
import BranchData from "./BranchData";

const ManageBranch = () => {
  const { id, type } = useParams();
  const navigate = useNavigate();

  let content = null;
  switch (type) {
    case "view":
      content = <AddBranch type={type} />;
      break;
    case "edit":
      content = <AddBranch type={type} />;
      break;
    case "add":
      content = <AddBranch />;
      break;
    default:
      content = <BranchData />;
      break;
  }
  return content;
};

export default ManageBranch;
