import {
  Box,
  Stack,
  Typography,
  TextField,
  Grid,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useState } from "react";

function OtherInformation({
  data,
  type,
  setUserAssignment,
  numberOfAssign,
  formErrors,
}) {
  const handleTextChange = (field, value) => {
    setUserAssignment((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction={"column"}
      sx={{ paddingBlock: "16px" }}
    >
      <Stack
        direction={"column"}
        spacing={0}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            paddingBlock: "4px",
          }}
        >
          <Grid item xs={12} sm={10} md={1}>
            <Typography variant="h6">Tel. No. :</Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={2}>
            <FormControl
              variant="standard"
              sx={{ width: "100%", justifyContent: "center" }}
            >
              <TextField
                id="standard-position"
                type="text"
                variant="standard"
                disabled={type === "view" ? true : false}
                value={data.telephone_no}
                onChange={(e) =>
                  handleTextChange("telephone_no", e.target.value)
                }
                sx={{
                  "& .MuiInput-root": {
                    borderBottom: "1px solid", // Fixed bold underline
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={0} sm={0} md={1}></Grid>
          <Grid item xs={12} sm={10} md={1}>
            <Typography variant="h6">Fax. No. :</Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={2}>
            <FormControl
              variant="standard"
              sx={{ width: "100%", justifyContent: "center" }}
            >
              <TextField
                id="standard-position"
                type="text"
                disabled={type === "view" ? true : false}
                variant="standard"
                value={data.fax_no}
                onChange={(e) => handleTextChange("fax_no", e.target.value)}
                sx={{
                  "& .MuiInput-root": {
                    borderBottom: "1px solid", // Fixed bold underline
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Stack>
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            paddingBlock: "4px",
          }}
        >
          <Grid item xs={12} sm={10} md={2}>
            <Typography variant="h6">Email Address :</Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={5}>
            <FormControl
              variant="standard"
              sx={{ width: "100%", justifyContent: "center" }}
            >
              <TextField
                id="standard-position"
                type="text"
                disabled={type === "view" ? true : false}
                variant="standard"
                error={!!formErrors.email}
                helperText={formErrors.email}
                value={data.email}
                onChange={(e) => handleTextChange("email", e.target.value)}
                sx={{
                  "& .MuiInput-root": {
                    borderBottom: "1px solid", // Fixed bold underline
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Stack>
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            paddingBlock: "4px",
          }}
        >
          <Grid item xs={12} sm={10} md={2}>
            <Typography variant="h6">Website :</Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={5}>
            <FormControl
              variant="standard"
              sx={{ width: "100%", justifyContent: "center" }}
            >
              <TextField
                id="standard-position"
                type="text"
                disabled={type === "view" ? true : false}
                variant="standard"
                value={data.website}
                onChange={(e) => handleTextChange("website", e.target.value)}
                sx={{
                  "& .MuiInput-root": {
                    borderBottom: "1px solid", // Fixed bold underline
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Stack>
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            paddingBlock: "4px",
          }}
        >
          <Grid item xs={12} sm={10} md={1}>
            <Typography variant="h6">TIN :</Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={2}>
            <FormControl
              variant="standard"
              sx={{ width: "100%", justifyContent: "center" }}
            >
              <TextField
                id="standard-position"
                type="text"
                disabled={type === "view" ? true : false}
                variant="standard"
                value={data.tin}
                error={!!formErrors.tin}
                helperText={formErrors.tin}
                onChange={(e) => handleTextChange("tin", e.target.value)}
                sx={{
                  "& .MuiInput-root": {
                    borderBottom: "1px solid", // Fixed bold underline
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={0} sm={0} md={1}></Grid>
          <Grid item xs={12} sm={10} md={1}>
            <Typography variant="h6">SSS No. :</Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={2}>
            <FormControl
              variant="standard"
              sx={{ width: "100%", justifyContent: "center" }}
            >
              <TextField
                id="standard-position"
                type="text"
                disabled={type === "view" ? true : false}
                variant="standard"
                value={data.sss}
                error={!!formErrors.sss}
                helperText={formErrors.sss}
                onChange={(e) => handleTextChange("sss", e.target.value)}
                sx={{
                  "& .MuiInput-root": {
                    borderBottom: "1px solid", // Fixed bold underline
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Stack>
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            paddingBlock: "4px",
          }}
        >
          <Grid item xs={12} sm={10} md={1}>
            <Typography variant="h6">PhilHealth No. :</Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={2}>
            <FormControl
              variant="standard"
              sx={{ width: "100%", justifyContent: "center" }}
            >
              <TextField
                id="standard-position"
                type="text"
                disabled={type === "view" ? true : false}
                variant="standard"
                error={!!formErrors.philhealth}
                helperText={formErrors.philhealth}
                value={data.philhealth}
                onChange={(e) => handleTextChange("philhealth", e.target.value)}
                sx={{
                  "& .MuiInput-root": {
                    borderBottom: "1px solid", // Fixed bold underline
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={0} sm={0} md={1}></Grid>
          <Grid item xs={12} sm={10} md={1}>
            <Typography variant="h6">PAG-IBIG No. :</Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={2}>
            <FormControl
              variant="standard"
              sx={{ width: "100%", justifyContent: "center" }}
            >
              <TextField
                id="standard-position"
                type="text"
                error={!!formErrors.pagibig}
                helperText={formErrors.pagibig}
                disabled={type === "view" ? true : false}
                variant="standard"
                value={data.pagibig}
                onChange={(e) => handleTextChange("pagibig", e.target.value)}
                sx={{
                  "& .MuiInput-root": {
                    borderBottom: "1px solid", // Fixed bold underline
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Stack>
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            paddingBlock: "4px",
          }}
        >
          <Grid item xs={12} sm={10} md={1}>
            <Typography variant="h6">RDO Code :</Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={2}>
            <FormControl
              variant="standard"
              sx={{ width: "100%", justifyContent: "center" }}
            >
              <TextField
                id="standard-position"
                type="text"
                disabled={type === "view" ? true : false}
                variant="standard"
                value={data.rdo_code}
                onChange={(e) => handleTextChange("rdo_code", e.target.value)}
                sx={{
                  "& .MuiInput-root": {
                    borderBottom: "1px solid", // Fixed bold underline
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={0} sm={0} md={1}></Grid>
          <Grid item xs={12} sm={10} md={1}>
            <Typography variant="h6">Zip Code :</Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={2}>
            <FormControl
              variant="standard"
              sx={{ width: "100%", justifyContent: "center" }}
            >
              <TextField
                id="standard-position"
                type="text"
                disabled={type === "view" ? true : false}
                variant="standard"
                error={!!formErrors.zip_code}
                helperText={formErrors.zip_code}
                value={data.zip_code}
                onChange={(e) => handleTextChange("zip_code", e.target.value)}
                sx={{
                  "& .MuiInput-root": {
                    borderBottom: "1px solid", // Fixed bold underline
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Stack>
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            paddingBlock: "4px",
          }}
        >
          <Grid item xs={12} sm={10} md={2}>
            <Typography variant="h6">Sector :</Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={5}>
            <FormControl
              variant="standard"
              sx={{ width: "100%", justifyContent: "center" }}
            >
              <TextField
                id="standard-position"
                type="text"
                disabled={type === "view" ? true : false}
                variant="standard"
                value={data.sector}
                onChange={(e) => handleTextChange("sector", e.target.value)}
                sx={{
                  "& .MuiInput-root": {
                    borderBottom: "1px solid", // Fixed bold underline
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Stack>
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            paddingBlock: "8px",
          }}
        >
          <Grid item xs={12} sm={10} md={1}>
            <Typography variant="h6">Remarks :</Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={6}>
            <FormControl
              variant="standard"
              sx={{ width: "100%", justifyContent: "center" }}
            >
              <TextField
                id="standard-multiline-static"
                multiline
                disabled={type === "view" ? true : false}
                rows={2}
                variant="standard"
                value={data.remarks}
                onChange={(e) => handleTextChange("remarks", e.target.value)}
                sx={{
                  "& .MuiInput-root": {
                    borderBottom: "1px solid", // Fixed bold underline
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Stack>
        {type !== "add" && (
          <Stack
            direction="row"
            spacing={0}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              paddingBlock: "8px",
            }}
          >
            <Grid item xs={12} sm={10} md={6}>
              <Typography variant="h6">Number of Assign Guards :</Typography>
            </Grid>
            <Grid item xs={12} sm={10} md={1}>
              {numberOfAssign}
            </Grid>
          </Stack>
        )}
      </Stack>
    </Grid>
  );
}

export default OtherInformation;
