// authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";

const baseUrl = process.env.REACT_APP_WS_URL;
const baseUrlBack = process.env.REACT_APP_BACKEND_URL;
const initialState = {
  user: null,
  token: null,
  user_id: null,
};

// Thunk to handle logging out
export const logOutAsync = createAsyncThunk(
  "auth/logOutAsync",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      // Make a POST request to the logout endpoint
      // use the base URL from the baseQuery
      const response = await fetch(`${baseUrlBack}/logout`, {
        method: "POST",
        credentials: "include",
        //include cookies
      });

      if (!response.ok) {
        throw new Error("Logout failed");
      }

      // Dispatch the logOut action after successful API call
      dispatch(logOut());
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { user, accessToken } = action.payload;
      const decoded = jwtDecode(accessToken);
      const { id, _id } = decoded.UserInfo;
      state.user = user;
      state.token = accessToken;
      state.user_id = id;
      state.obj_id = _id;

      const socketService = require("../ws/initializeWebSocketService").default;
      socketService.connect(baseUrl);
    },
    logOut: (state) => {
      state.user = null;
      state.token = null;
      state.user_id = null;
      state.obj_id = null;

      const socketService = require("../ws/initializeWebSocketService").default;
      socketService.disconnect();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logOutAsync.fulfilled, (state) => {
        console.log("Logout successful");
        // Reset the state upon successful logout
      })
      .addCase(logOutAsync.rejected, (state, action) => {
        console.error("Logout failed:", action.payload);
      });
  },
});

export const { setCredentials, logOut } = authSlice.actions;
export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
export const selectCurrentUserId = (state) => state.auth.user_id;

export const selectCurrentObjId = (state) => state.auth.obj_id;
