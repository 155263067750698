// CustomRoutes.js
import React from "react";
import Layout from "../components/Layout";
import RequireAuth from "../app/api/auth/RequireAuth";

const CustomRoutes = ({
  component: Component,
  layout: LayoutComponent = Layout,
  authRequired = true,
  allowedRoles = [],
  ...rest
}) => (
  <LayoutComponent>
    {authRequired ? (
      <RequireAuth allowedRoles={allowedRoles}>
        <Component {...rest} />
      </RequireAuth>
    ) : (
      <Component {...rest} />
    )}
  </LayoutComponent>
);

export default CustomRoutes;
