import React, { useEffect, useState } from "react";
import { useGetPayrollListQuery } from "../../../app/api/payroll/generatePayroll";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { Navigate, useNavigate } from "react-router-dom";
import CustomizedMenus from "../../../components/buttons/CustomizeMenu";
import DISTable from "../../../components/tables/DISTable";
import useAuth from "../../../hooks/useAuth";
import IconBreadcrumbs from "../../../components/shared/Breadcrumb";
import { ToastContainer, toast } from "react-toastify";

function decimalFormat(num) {
  return num.toLocaleString("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

const optionMonth = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];

const headers = [
  {
    id: "fullname",
    label: "NAME",
    sortable: true,
    searchable: true,
  },
  {
    id: "position",
    label: "POSITION",
    sortable: true,
    filterable: true, // Enabling filter for this field
  },
  {
    id: "salary",
    label: "SALARY",
  },
  {
    id: "area_assign",
    label: "AREA ASSIGN",
    sortable: true,
    filterable: true, // Enabling filter for this field
  },
  {
    id: "reliver_assign",
    label: "RELIEVER ASSIGN",
  },
  {
    id: "encoded_by",
    label: "ENCODED BY",
  },
  {
    id: "payroll_date",
    label: "PAYROLL DATE",
  },
  {
    id: "actions",
    label: "Actions",
  },
];
export default function PayrollDataN() {
  const { branch_no, isSuperAdmin } = useAuth();

  const [tableData, setTableData] = React.useState([]);
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [totalPageNumber, setTotalPageNumber] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const {
    data,
    error,
    isLoading: isLoadingPayroll,
  } = useGetPayrollListQuery(
    {
      ...(isSuperAdmin && { branch_id: branch_no }),
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const handleCreatePayroll = () => {
    navigate("/manage-payroll/create-payroll");
  };

  const custMenuOption = [
    {
      label: "Create Payroll",
      icon: <GroupAddIcon />,
      action: handleCreatePayroll,
    },
    {
      label: "Create 13th Month Pay",
      icon: <GroupAddIcon />,
      action: () => {
        navigate("/manage-payroll/create-13th-month");
      },
    },
    {
      label: "Import Payroll",
      icon: <GroupAddIcon />,
      action: () => {
        //handleImportPayroll();
      },
    },
    {
      label: "Download Payroll Template",
      icon: <GroupAddIcon />,
      action: () => {
        window.open("/csv-template/payroll-format.csv", "_blank");
      },
    },
  ];

  const options = {
    allowEdit: true,
    allowDelete: true,
    allowView: true,
    allowAdd: true,
    allowImport: true,
    allowExport: true,
    allowSearch: true,
    numberPerPage: 10,
    selectable: false,
    page: setPage,
    totalPageNumber: 0,
    tableTitle: "",
    isLoading: isLoading,
    setSearchValue: setSearchValue,
    searchValue: searchValue,
    setIsLoading: setIsLoading,
    setRowsPerPage: setRowsPerPage,
  };
  /*const handleEdit = (id) => {
    navigate(`/manage-payroll/edit/${id}`);
  };*/

  const handleDelete = (id) => {
    console.log("Delete", id);
  };
  /*
  const handleView = (id) => {
    navigate(`/manage-payroll/view/${id}`);
  };*/

  useEffect(() => {
    if (data) {
      const payrollData = data.map((payroll) => {
        return {
          id: payroll.id,
          fullname: payroll.first_name + " " + payroll.last_name,
          position: payroll.position,
          area_assign: payroll.area_assign,
          reliver_assign: payroll.reliever_assign_name,
          encoded_by: payroll.encoded_name,
          payroll_date:
            optionMonth[payroll.month - 1].label +
            " " +
            payroll.year +
            " " +
            (payroll.period === 1 ? "1-15th" : "16-30th"),
          salary: `₱` + decimalFormat(payroll.total_salary),
        };
      });
      setTableData(payrollData);
    }
  }, [data]);

  const customButton = (
    <>
      <CustomizedMenus options={custMenuOption} />
    </>
  );

  console.log(data);
  return (
    <>
      <IconBreadcrumbs
        data={[
          { link: "/dashboard", title: "Home" },
          { link: "/manage-payroll/", title: "Manage Payroll" },
          { title: "Payroll List" },
        ]}
        title={"Payroll List"}
      />
      <DISTable
        headers={headers}
        data={tableData}
        options={options}
        actions={{ handleDelete }}
        customButton={customButton}
      />
    </>
  );
}
