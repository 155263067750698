import React, { useEffect, useState, useRef } from "react";
import {
  useGetListAssignmentQuery,
  useUpdateUserAssignmentMutation,
} from "../../../../app/api/users/userAssignment";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Box,
  Stack,
} from "@mui/material";
import useAuth from "../../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import DISTable from "../../../../components/tables/DISTable";
import FullScreenDialog from "../../../../components/dialog/FullScreenDialog";
import AlertDialog from "../../../../components/modal/AlertDialog";
import IconBreadcrumbs from "../../../../components/shared/Breadcrumb";

const headers = [
  {
    id: "area_assign",
    label: "Area Assign",
    sortable: true,
    searchable: true,
  },
  {
    id: "countNumberOfAssign",
    label: "Number of Assign",
    sortable: true,
  },
  {
    id: "status",
    label: "Status",
    sortable: true,
    searchable: true,
  },

  {
    id: "actions",
    label: "Actions",
  },
];
export default function ManageAOR() {
  const navigate = useNavigate();
  const [updateUserAssignment] = useUpdateUserAssignmentMutation();
  const { branch_no } = useAuth();
  const [totalPageNumber, setTotalPageNumber] = useState(0);
  const [dialogMessage, setDialogMessage] = useState("");
  const [clientStatus, setClientStatus] = useState("");

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogId, setDialogId] = useState("");

  const {
    data,
    error,
    isLoading: isAssignLoading,
  } = useGetListAssignmentQuery(
    {
      branch_no: branch_no,
      page: page + 1,
      search: searchValue,
      rowsPerPage: rowsPerPage,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const options = {
    allowEdit: true,
    allowDelete: true,
    allowView: true,
    allowAdd: true,
    allowImport: true,
    allowExport: true,
    allowSearch: true,
    numberPerPage: 10,
    selectable: false,
    page: setPage,
    totalPageNumber: 0,
    tableTitle: "",
    isLoading: isLoading,
    setSearchValue: setSearchValue,
    searchValue: searchValue,
    setIsLoading: setIsLoading,
    setRowsPerPage: setRowsPerPage,
  };

  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    if (data) {
      const list = data[0].data.map((item) => {
        return {
          id: item.id,
          area_assign: item.area_name,
          countNumberOfAssign: item.countNumberOfAssign,
          status: item.assignment_status === 0 ? "Active" : "Inactive",
        };
      });
      setTableData(list);
      setTotalPageNumber(data[0].totalCount[0]?.count || 0);
    }
  }, [data, page]);

  options.totalPageNumber = totalPageNumber;
  // Apply filters and search to users
  const handleView = (id) => {
    navigate(`/manage-client/view/${id}`);
  };

  const handleDelete = (id) => {
    setDialogOpen(true);
    setDialogId(id);
  };

  const handlePrint = () => {
    setOpenModal(true);
  };

  const handleYesDelete = async () => {
    try {
      await updateUserAssignment({
        assignment_id: dialogId,
        status: clientStatus === "Active" ? 1 : 0,
        isActive: clientStatus === "Active" ? 1 : 0,
      });
      setDialogOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  /*const componentRef = useRef();
  const styleRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Application Form Print",
    onBeforeGetContent: () => {
      // Dynamically create and add the print styles
      const styleElement = document.createElement("style");
      styleElement.innerHTML = `
        @media print {
          @page {
            size: 8.5in 13in !important;
            margin: 8.13mm 13.46mm !important; /*Narrow*/
  /*}
          .print-content {
            color: black;
            display:block !important;
          }
          .PrintContainer-PageOne {
            padding: 0;
            margin: 0;
          }
          .PrintContainer-PageTwo {
            padding: 0;
            margin: 0 auto;
          }
          .PrintContainer-PageThree {
            padding: 0;
            margin: 0;
          }
          
        }
      `;
      document.head.appendChild(styleElement);
      styleRef.current = styleElement; // Store reference to the style element
    },
    onAfterPrint: () => {
      // Remove the dynamically added style after printing
      if (styleRef.current) {
        document.head.removeChild(styleRef.current);
      }
    },
  });

  let generatePrintClient = sortedUsers.map((user) => (
    <TableRow key={user.id}>
      <TableCell>{user.area_assign}</TableCell>
      <TableCell>{user.countNumberOfAssign}</TableCell>
      <TableCell>{user.status}</TableCell>
    </TableRow>
  ));*/

  const handleSwitchOnOff = (id, status) => {
    setDialogOpen(true);
    setDialogId(id);
    setClientStatus(status);
    setDialogMessage(
      status === "Active"
        ? "Are you sure you want to deactivate this client?"
        : "Are you sure you want to activate this client?"
    );
  };

  const actions = {
    handleSwitchOnOff,
    handleView,
  };

  const customButton = (
    <>
      <Stack
        direction={{
          xs: "column",
          sm: "row",
        }}
        spacing={2}
        sx={{ width: "100%" }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/manage-client/add")}
          sx={{ mr: 1, width: "100%", minHeight: "38px", height: "100%" }}
        >
          Add Client
        </Button>

        <Button
          variant="contained"
          color="primary"
          sx={{ width: "100%" }}
          onClick={handlePrint}
        >
          Print Preview
        </Button>
      </Stack>
    </>
  );

  const printData = {
    title: "Print Preview",
    type: "printClient",
  };

  return (
    <>
      <IconBreadcrumbs
        data={[
          { link: "/dashboard", title: "Home" },
          { link: "/administrative", title: "Administrative" },
          { link: "/administrative/manage-clients", title: "Manage Clients" },
        ]}
        title="Manage Client"
      />
      <Paper elevation={3}>
        <DISTable
          headers={headers}
          data={tableData}
          options={options}
          actions={actions}
          customButton={customButton}
        />
      </Paper>

      <Box
        className="print-content"
        sx={{
          width: "100%",
          overflowX: "auto", // Enable horizontal scrolling for smaller screens
          padding: 2, // Optional padding to provide some spacing
          display: "none",
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            minWidth: 300, // Ensure there's a minimum width for the table
            maxWidth: "100%", // Allow it to grow up to 100% of its container
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>Area Assign</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Number of Assign</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Status</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Box>

      <FullScreenDialog
        open={openModal}
        close={() => setOpenModal(false)}
        data={printData}
      />

      <AlertDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        title={"Update Client"}
        content={dialogMessage}
        dialogId={dialogId}
        action={handleYesDelete}
      />
    </>
  );
}
