import React, { useEffect, useRef } from "react";
import {
  Avatar,
  Grid,
  Stack,
  Box,
  Typography,
  FormControl,
  TextField,
  FormHelperText,
} from "@mui/material";
import IconMenu from "../../../../components/buttons/ButtonList";
import { useReactToPrint } from "react-to-print";
import "./ApplicationForm.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import PersonalBackground from "./PersonalBackground.js";
import EducationalBackground from "./EducationalBackground.js";
import FamilyBackground from "./FamilyBackground.js";
import Eligibilty from "./Eligibilty.js";
import WorkExperience from "./WorkExperience.js";
import Affilations from "./Affilations.js";
import SkillsAndInterest from "./SkillsAndInterest.js";
import CharacterHealth from "./CharacterHealth.js";
import References from "./References.js";

const theme = createTheme({
  components: {
    MuiInput: {
      styleOverrides: {
        input: {
          fontSize: "16px", // Set default font size for all inputs
          "@media print": {
            fontSize: "11px", // Set font size for printing
          },
          padding: 0,
        },
      },
    },
  },
});

function ApplicationForm() {
  const componentRef = useRef();
  const styleRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Application Form Print",
    onBeforeGetContent: () => {
      // Dynamically create and add the print styles
      const styleElement = document.createElement("style");
      styleElement.innerHTML = `
        @media print {
          @page {
            size: 8.5in 13in !important;
            margin: 8.13mm 13.46mm !important; /*Narrow*/
          }
          .print-content {
            color: black;
          }
          .PrintContainer-PageOne {
            padding: 0;
            margin: 0;
          }
          .PrintContainer-PageTwo {
            padding: 0;
            margin: 0 auto;
          }
          .PrintContainer-PageThree {
            padding: 0;
            margin: 0;
          }
          .pichel-agency-text {
            font-size: 20px;
          }
          .management-text {
            font-size: 16px;
          }
          .acc-fina-text {
            font-size: 15px;
          }
          .document-title-text {
            font-size: 18px;
            padding: 15px 0px;
          }
          .print-avatar {
            width: 130px !important;
            height: 130px !important;
          }
          .fill-texts {
            font-size: 13px;
          }
          .blank-texts {
            width: 18ch;
          }
          .helper-text {
            font-size: 10px;
          }
          .position-stack {
            margin-top: 2px !important;
          }
          .salary-stack {
            margin-top: 0px !important;
          }
          .print-centered {
            display: flex;
            align-items: center;
          }

          .custom-header {
            font-size: 13px;
          }
          .side-header {
            font-size: 11px;
          }
          .cell-title {
            font-size: 10px;
          }
          .consent-statement {
            font-size: 13px;
          }
        }
      `;
      document.head.appendChild(styleElement);
      styleRef.current = styleElement; // Store reference to the style element
    },
    onAfterPrint: () => {
      // Remove the dynamically added style after printing
      if (styleRef.current) {
        document.head.removeChild(styleRef.current);
      }
    },
  });

  let content = (
    <>
      <ThemeProvider theme={theme}>
        <IconMenu type={"EmployeeDTR"} handlePrint={handlePrint} />
        <Box
          sx={{ paddingBlock: "32px" }}
          ref={componentRef}
          className="PrintContainer-PageOne">
          <Grid
            container
            direction={"row"}
            spacing={0}
            justifyContent="space-evenly"
            alignItems="center">
            <Grid item xs={8} sx={{ textAlign: "start" }} md={8}>
              <Typography
                variant="h4"
                className="pichel-agency-text"
                sx={{ fontWeight: "800" }}>
                PICHEL AGENCY
              </Typography>
              <Typography variant="h5" className="management-text">
                MANAGEMENT
              </Typography>
              <Typography variant="h5" className="acc-fina-text">
                Accounting & Finance
              </Typography>
            </Grid>
            <Grid item xs={8} sx={{ textAlign: "start" }} md={8}>
              <Typography
                variant="h4"
                className="document-title-text"
                sx={{
                  fontWeight: "600",
                  textDecoration: "underline",
                  textAlign: "center",
                  padding: "38px 0px",
                }}>
                EMPLOYMENT APPLICATION FORM
              </Typography>
            </Grid>
            <Grid
              item
              xs={7}
              md={7}
              sx={{
                padding: 0,
              }}>
              <Stack direction={"column"} spacing={1}>
                <Stack direction={"row"} spacing={2} alignItems="center">
                  {/* Center items vertically */}
                  <Typography className="fill-texts" variant="h6">
                    DATE OF APPLICATION:
                  </Typography>
                  <FormControl
                    className="blank-texts"
                    variant="standard"
                    sx={{ width: "30ch" }}>
                    <TextField
                      id="standard-date"
                      type="text"
                      variant="standard"
                      inputProps={{
                        style: {
                          textAlign: "center",
                          textTransform: "uppercase",
                        },
                      }}
                    />
                  </FormControl>
                </Stack>
                <Stack
                  direction={"row"}
                  spacing={2}
                  className="position-stack"
                  alignItems="center">
                  {/* Center items vertically */}
                  <Typography className="fill-texts" variant="h6">
                    POSITION APPLIED FOR:
                  </Typography>
                  <FormControl
                    className="blank-texts"
                    variant="standard"
                    sx={{ width: "30ch", justifyContent: "center" }}>
                    <TextField
                      id="standard-position"
                      type="text"
                      variant="standard"
                      inputProps={{
                        style: {
                          textAlign: "center",
                          textTransform: "uppercase",
                        },
                      }}
                    />
                    <FormHelperText
                      id="standard-first"
                      className="helper-text"
                      sx={{
                        textAlign: "center",
                        fontWeight: "600",
                        fontSize: "13px",
                      }}>
                      First Choice
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    className="blank-texts"
                    variant="standard"
                    sx={{ width: "30ch" }}>
                    <TextField
                      id="standard-date"
                      type="text"
                      variant="standard"
                      inputProps={{
                        style: {
                          textAlign: "center",
                          textTransform: "uppercase",
                        },
                      }}
                    />
                    <FormHelperText
                      id="standard-second"
                      className="helper-text"
                      sx={{
                        textAlign: "center",
                        fontWeight: "600",
                        fontSize: "13px",
                      }}>
                      Second Choice
                    </FormHelperText>
                  </FormControl>
                </Stack>
                <Stack
                  direction={"row"}
                  spacing={2}
                  className="salary-stack"
                  alignItems="center">
                  {" "}
                  {/* Center items vertically */}
                  <Typography className="fill-texts" variant="h6">
                    EXPECTED SALARY:
                  </Typography>
                  <FormControl
                    className="blank-texts"
                    variant="standard"
                    sx={{ width: "30ch", justifyContent: "center" }}>
                    <TextField
                      id="standard-salary"
                      type="text"
                      variant="standard"
                      inputProps={{
                        style: {
                          textAlign: "center",
                          textTransform: "uppercase",
                        },
                      }}
                    />
                  </FormControl>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={2} md={2}>
              <Stack alignItems={"center"} justifyContent={"center"}>
                <Avatar
                  variant="rounded"
                  className="print-avatar" // Add class for printing
                  sx={{
                    width: "230px",
                    height: "230px",
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
          {/* Uncomment if needed */}
          <Grid
            container
            spacing={0}
            justifyContent={"center"}
            alignContent={"center"}>
            <Grid item sm={12} md={12}>
              <PersonalBackground />
            </Grid>
            <Grid item sm={12} md={12}>
              <EducationalBackground />
            </Grid>
            <Grid item sm={12} md={12}>
              <FamilyBackground />
            </Grid>
          </Grid>
          <Box
            ref={componentRef}
            className="PrintContainer-PageTwo"
            sx={{
              pageBreakBefore: "always", // Ensure a new page starts here when printing
              "@media print": {
                pageBreakBefore: "always", // Apply only when printing
              },
            }}>
            <Grid
              container
              spacing={0}
              justifyContent={"center"}
              alignContent={"center"}>
              <Grid item sm={12} md={12}>
                <Eligibilty />
              </Grid>
              <Grid item sm={12} md={12}>
                <WorkExperience />
              </Grid>
              <Grid item sm={12} md={12}>
                <Affilations />
              </Grid>
              <Grid item sm={12} md={12}>
                <SkillsAndInterest />
              </Grid>
            </Grid>
            <Box
              ref={componentRef}
              className="PrintContainer-PageThree"
              sx={{
                pageBreakBefore: "always", // Ensure a new page starts here when printing
                "@media print": {
                  pageBreakBefore: "always", // Apply only when printing
                },
              }}>
              <Grid
                container
                spacing={0}
                justifyContent={"center"}
                alignContent={"center"}>
                <Grid item sm={12} md={12}>
                  <CharacterHealth />
                </Grid>
                <Grid item sm={12} md={12}>
                  <References />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );

  return content;
}

export default ApplicationForm;
