import { Avatar, Box } from "@mui/material";
import React from "react";

function Footer() {
  return (
    <Box
      sx={{
        textAlign: "center",
        fontWeight: 700,
        mb: 2,
        mt: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Avatar
        alt="DIS TPD"
        src="/logo/jservicehub1.png"
        sx={{
          width: "50px",
          height: "50px",
          objectFit: "contain", // Ensures the logo fits within the Avatar without cropping
        }}
        variant="square"
      />
      Developed by DIS TPD
    </Box>
  );
}

export default Footer;
