import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../apiSlice";

const postsAdapter = createEntityAdapter();

const initialState = postsAdapter.getInitialState();

export const payrollApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    generateNewPayroll: builder.mutation({
      query: ({ formData }) => ({
        url: `/payroll`,
        method: "POST",
        body: formData,
      }),
    }),
    generateManualPayroll: builder.mutation({
      query: ({ formData }) => ({
        url: `/payroll/generateManualPayroll`,
        method: "POST",
        body: formData,
      }),
    }),
    createSignlePayroll: builder.mutation({
      query: ({ formData }) => ({
        url: `/payroll/createSinglePayroll`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: [
        { type: "Payroll", id: "payroll-data" },
        { type: "Users", id: "Members" },
      ],
    }),
    getPayroll: builder.query({
      query: ({ month, year, period, branch_id, user_id, isSuperAdmin }) => {
        const params = new URLSearchParams();
        if (month) params.append("month", month);
        if (year) params.append("year", year);
        if (period) params.append("period", period);
        if (branch_id) params.append("branch_id", branch_id);
        if (user_id) params.append("user_id", user_id);

        return `/payroll/generatePayroll?${params.toString()}`;
      },
      providesTags: [{ type: "Payroll", id: "payroll-data" }],
    }),
    getCheckPayroll: builder.query({
      query: ({ branch_id, isSuperAdmin, year }) => {
        const params = new URLSearchParams();
        if (branch_id) params.append("branch_id", branch_id);
        if (isSuperAdmin) params.append("isSuperAdmin", isSuperAdmin);
        if (year) params.append("year", year);

        return `/payroll/checkPayroll?${params.toString()}`;
      },
    }),
    getPayrollList: builder.query({
      query: ({ branch_id }) => {
        const params = new URLSearchParams();
        if (branch_id) params.append("branch_id", branch_id);

        return `/payroll/getPayrollList?${params.toString()}`;
      },
    }),
    get13thmonth: builder.query({
      query: ({ branch_id }) => {
        const params = new URLSearchParams();
        if (branch_id) params.append("branch_id", branch_id);

        return `/payroll/get13thMonth?${params.toString()}`;
      },
    }),
    updatePayroll: builder.mutation({
      query: ({ formData }) => ({
        url: `/payroll/updatePayroll`,
        method: "POST",
        body: formData,
      }),
    }),
  }),
});

export const {
  useGenerateNewPayrollMutation,
  useGetPayrollQuery,
  useGetCheckPayrollQuery,
  useGenerateManualPayrollMutation,

  useCreateSignlePayrollMutation,
  useGetPayrollListQuery,
  useGet13thmonthQuery,

  useUpdatePayrollMutation,
} = payrollApiSlice;
