import React, { useEffect, useState } from "react";
import { Button, Paper, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import BranchInformation from "./branchForms/BranchInformation";
import OtherInformation from "./branchForms/OtherInformation";
import {
  useGetUserAssignmentQuery,
  useUpdateUserAssignmentMutation,
  useAddUserAssignmentMutation,
} from "../../../../app/api/users/userAssignment";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AddBranch() {
  const [formErrors, setFormErrors] = useState({});
  const { id, type } = useParams();
  const navigate = useNavigate();
  const { branch_no } = useAuth();
  const [updateUserAssignment, { isLoading: isUpdating }] =
    useUpdateUserAssignmentMutation();
  const [addUserAssignment, { isLoading: isAdding }] =
    useAddUserAssignmentMutation();

  const [numberOfAssign, setNumberOfAssign] = useState(0);

  const [userAssignment, setUserAssignment] = useState({
    area_name: "",
    registered_name: "",
    code_no: "",
    address: "",
    telephone_no: "", // Fixed typo here
    cellphone_no: "",
    fax_no: "",
    email: "",
    website: "",
    tin: "",
    sss: "",
    philhealth: "",
    pagibig: "",
    rdo_code: "",
    zip_code: "",
    sector: "",
    remarks: "",
    status: 1,
    assignment_id: id,
  });

  const {
    data: userAssignmentData,
    error,
    isLoading,
  } = useGetUserAssignmentQuery(
    { branch_id: branch_no, id: id },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (userAssignmentData) {
      setUserAssignment({
        area_name: userAssignmentData.assignmentDetail.area_name || "",
        registered_name:
          userAssignmentData.assignmentDetail.registered_name || "",
        code_no: userAssignmentData.assignmentDetail.code_no || "",
        address: userAssignmentData.assignmentDetail.address || "",
        telephone_no: userAssignmentData.assignmentDetail.telephone_no || "", // Fixed typo
        cellphone_no: userAssignmentData.assignmentDetail.cellphone_no || "",
        fax_no: userAssignmentData.assignmentDetail.fax_no || "",
        email: userAssignmentData.assignmentDetail.email || "",
        website: userAssignmentData.assignmentDetail.website || "",
        tin: userAssignmentData.assignmentDetail.tin || "",
        sss: userAssignmentData.assignmentDetail.sss || "",
        philhealth: userAssignmentData.assignmentDetail.philhealth || "",
        pagibig: userAssignmentData.assignmentDetail.pagibig || "",
        rdo_code: userAssignmentData.assignmentDetail.rdo_code || "",
        zip_code: userAssignmentData.assignmentDetail.zip_code || "",
        sector: userAssignmentData.assignmentDetail.sector || "",
        remarks: userAssignmentData.assignmentDetail.remarks || "",
        status: userAssignmentData.assignmentDetail.status,
        assignment_id: id,
      });
      setNumberOfAssign(userAssignmentData.countNumberOfAssign);
    }
  }, [userAssignmentData]);

  const handleUpdate = async () => {
    try {
      if (!validateForm()) return;
      const res = await updateUserAssignment({
        ...userAssignment,
      }).unwrap();
      if (res) {
        toast.success("Assignment updated successfully");
        navigate("/manage-client/view/" + id);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleEdit = () => {
    navigate("/manage-client/edit/" + id);
  };

  const handleBackBtn = () => {
    navigate("/administrative/manage-clients");
  };

  const validateForm = () => {
    const errors = {};

    if (!userAssignment.area_name) {
      errors.area_name = "Area name is required";
    }
    if (!userAssignment.registered_name) {
      errors.registered_name = "Registered name is required";
    }
    if (!userAssignment.code_no) {
      errors.code_no = "Code number is required";
    }
    if (!userAssignment.address) {
      errors.address = "Address is required";
    }
    if (!userAssignment.email) {
      errors.email = "Email is required";
    }
    if (!userAssignment.tin) {
      errors.tin = "TIN is required";
    }
    if (!userAssignment.sss) {
      errors.sss = "SSS is required";
    }
    if (!userAssignment.philhealth) {
      errors.philhealth = "Philhealth is required";
    }
    if (!userAssignment.pagibig) {
      errors.pagibig = "Pagibig is required";
    }
    if (!userAssignment.zip_code) {
      errors.zip_code = "Zip code is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleAdd = async () => {
    try {
      if (!validateForm()) return;
      const res = await addUserAssignment({
        ...userAssignment,
      }).unwrap();
      if (res) {
        toast.success("Assignment added successfully");
        navigate("/manage-client/view/" + res.id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Paper
        elevation={3}
        sx={{ p: 2, backgroundColor: "#D1F3F1", width: "100%" }}
      >
        <Paper elevation={3} sx={{ p: 2, width: "100%" }}>
          <Stack direction="row" spacing={2}>
            <Button variant="contained" onClick={handleBackBtn}>
              Back
            </Button>
            {type === "view" ? (
              <LoadingButton
                startIcon={<SaveIcon />}
                variant="contained"
                color="secondary"
                onClick={handleEdit}
              >
                EDIT
              </LoadingButton>
            ) : type === "add" ? (
              <LoadingButton
                startIcon={<SaveIcon />}
                variant="contained"
                color="primary"
                onClick={handleAdd} // Assuming you have a handleAdd function for adding
                loading={isAdding} // Assuming you have a state for loading when adding
              >
                ADD
              </LoadingButton>
            ) : (
              <LoadingButton
                startIcon={<SaveIcon />}
                variant="contained"
                color="secondary"
                onClick={handleUpdate}
                loading={isUpdating} // Assuming you have a state for loading when updating
              >
                UPDATE
              </LoadingButton>
            )}
          </Stack>
          <BranchInformation
            data={userAssignment}
            setUserAssignment={setUserAssignment}
            type={type}
            formErrors={formErrors}
          />
          <OtherInformation
            data={userAssignment}
            setUserAssignment={setUserAssignment}
            numberOfAssign={numberOfAssign}
            type={type}
            formErrors={formErrors}
          />
          <Stack
            sx={{
              paddingBlock: "24px",
              alignItems: "center",
            }}
          >
            {/*<LoadingButton
              color="secondary"
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
              type="submit"
              sx={{
                fontSize: "1rem",
                width: "11ch",
              }}
            >
              <span>Save</span>
            </LoadingButton>*/}
          </Stack>
        </Paper>
      </Paper>
      <ToastContainer />
    </>
  );
}

export default AddBranch;
