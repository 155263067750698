import { Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ResponsiveList from "../../../components/list/ResponsiveList";
import { useWorkTypeWidgetQuery } from "../../../app/api/reports/dashboardApiSlice";
import useAuth from "../../../hooks/useAuth";

function EmployeeStatusWidget() {
  const { branch_no, dashboard } = useAuth();
  const [employeeStatus, setEmployeeStatus] = useState({
    regular: 0,
    casual: 0,
    contractual: 0,
    ojt: 0,
    partTime: 0,
  });
  const { data, error, isLoading } = useWorkTypeWidgetQuery(
    { ...(dashboard === true ? { branch_no: branch_no } : {}) },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    console.log(data);
    if (data) {
      setEmployeeStatus({
        regular: data.regular,
        casual: data.casual,
        contractual: data.contractual,
        ojt: data.ojt,
        partTime: data.partTime,
      });
    }
  }, [data]);

  console.log(employeeStatus);

  const categories = [
    { title: "REGULAR", value: employeeStatus.regular },
    { title: "CASUAL", value: employeeStatus.casual },
    { title: "CONTRACTUAL", value: employeeStatus.contractual },
    { title: "OJT", value: employeeStatus.ojt },
    { title: "PART TIME", value: employeeStatus.partTime },
  ];
  return (
    <Paper elevation={3} sx={{ p: 2, height: "100%" }}>
      <Stack spacing={2}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            textAlign: "center",
            backgroundColor: "#E1F7F5",
            color: "black",
          }}
        >
          EMPLOYEE STATUS
        </Typography>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <ResponsiveList categories={categories} />
        </Stack>
      </Stack>
    </Paper>
  );
}

export default EmployeeStatusWidget;
