import { List, ListItem, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useBirthdayWidgetQuery } from "../../../app/api/reports/dashboardApiSlice";
import dayjs from "dayjs";
import useAuth from "../../../hooks/useAuth";

function BirthdayWidget() {
  const { dashboard, branch_no } = useAuth();
  const [birthdateList, setBirthdateList] = React.useState([]);
  const { data, isLoading } = useBirthdayWidgetQuery(
    { ...(dashboard === true ? { branch_no: branch_no } : {}) },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (data) {
      const birthdate = data.map((item) => {
        return {
          branch:
            item.branch_no === 1
              ? "Pichel"
              : item.branch_no === 2
              ? "Eminence"
              : "SSP",
          name:
            item.personal_info.first_name + " " + item.personal_info.last_name,
          value: dayjs(item.personal_info.birthdate).format("MMMM DD, YYYY"),
        };
      });
      setBirthdateList(birthdate);
    }
  }, [data, isLoading]);

  let birthdayList = (
    <List
      sx={{
        maxHeight: "150px",
        overflowY: "auto",
      }}
    >
      {birthdateList.map(({ branch, name, value }, index) => (
        <ListItem
          key={index}
          sx={{
            backgroundColor: index % 2 === 0 ? "#E1F7F5" : "white",
            fontWeight: 700,
            justifyContent: "left",
            textAlign: "center",
            "&:last-child": {
              borderBottom: "none",
            },
          }}
        >
          {branch} - {name} - {value}
        </ListItem>
      ))}
    </List>
  );

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        maxHeight: "300px",
        height: "209px",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: 700,
          textAlign: "center",
          backgroundColor: "#E1F7F5",
          color: "black",
        }}
      >
        Birthday's
      </Typography>
      {birthdayList}
    </Paper>
  );
}

export default BirthdayWidget;
