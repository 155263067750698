import {
  Box,
  Stack,
  Typography,
  TextField,
  Grid,
  Select,
  MenuItem,
  Button,
  Paper,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useState } from "react";

function BranchInformation({ data, setUserAssignment, type, formErrors }) {
  const handleTextChange = (field, value) => {
    setUserAssignment((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction={"column"}
      sx={{ paddingBlock: "16px" }}
    >
      <Stack
        direction={"column"}
        spacing={0}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          paddingBlock: "24px",
        }}
      >
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            paddingBlock: "4px",
          }}
        >
          <Grid item xs={12} sm={10} md={2}>
            <Typography variant="h6">Code* :</Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={4}>
            <FormControl
              variant="standard"
              sx={{ width: "100%", justifyContent: "center" }}
            >
              <TextField
                id="standard-position"
                type="text"
                variant="standard"
                value={data.code_no}
                disabled={type === "view" ? true : false}
                error={!!formErrors.code_no}
                helperText={formErrors.code_no}
                onChange={(e) => handleTextChange("code_no", e.target.value)}
                sx={{
                  "& .MuiInput-root": {
                    borderBottom: "1px solid", // Fixed bold underline
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={10} md={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.status === 0 ? true : false}
                  disabled={type === "view" ? true : false}
                  onChange={(e) =>
                    handleTextChange("status", e.target.checked ? 1 : 0)
                  }
                />
              }
              label="Active"
            />
          </Grid>
        </Stack>
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            paddingBlock: "4px",
          }}
        >
          <Grid item xs={12} sm={10} md={2}>
            <Typography variant="h6">Trade Name* :</Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={5}>
            <FormControl
              variant="standard"
              sx={{ width: "100%", justifyContent: "center" }}
            >
              <TextField
                id="standard-position"
                type="text"
                variant="standard"
                disabled={type === "view" ? true : false}
                value={data.area_name}
                error={!!formErrors.area_name}
                helperText={formErrors.area_name}
                onChange={(e) => handleTextChange("area_name", e.target.value)}
                sx={{
                  "& .MuiInput-root": {
                    borderBottom: "1px solid", // Fixed bold underline
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Stack>
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            paddingBlock: "4px",
          }}
        >
          <Grid item xs={12} sm={10} md={2}>
            <Typography variant="h6">Registered Name* :</Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={5}>
            <FormControl
              variant="standard"
              sx={{ width: "100%", justifyContent: "center" }}
            >
              <TextField
                id="standard-position"
                type="text"
                variant="standard"
                disabled={type === "view" ? true : false}
                value={data.registered_name}
                error={!!formErrors.registered_name}
                helperText={formErrors.registered_name}
                onChange={(e) =>
                  handleTextChange("registered_name", e.target.value)
                }
                sx={{
                  "& .MuiInput-root": {
                    borderBottom: "1px solid", // Fixed bold underline
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Stack>
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            paddingBlock: "4px",
          }}
        >
          <Grid item xs={12} sm={10} md={2}>
            <Typography variant="h6">Address* :</Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={5}>
            <FormControl
              variant="standard"
              sx={{ width: "100%", justifyContent: "center" }}
            >
              <TextField
                id="standard-position"
                type="text"
                variant="standard"
                disabled={type === "view" ? true : false}
                sx={{
                  "& .MuiInput-root": {
                    borderBottom: "1px solid", // Fixed bold underline
                    display: "none",
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Stack>
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Grid item xs={12} sm={10} md={7}>
            <FormControl
              variant="standard"
              sx={{ width: "100%", justifyContent: "center" }}
            >
              <TextField
                id="standard-multiline-static"
                multiline
                rows={2}
                variant="standard"
                disabled={type === "view" ? true : false}
                value={data.address}
                error={!!formErrors.address}
                helperText={formErrors.address}
                onChange={(e) => handleTextChange("address", e.target.value)}
                sx={{
                  "& .MuiInput-root": {
                    borderBottom: "1px solid", // Fixed bold underline
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Stack>
        {/* <Grid
          item
          xs={12}
          md={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Box
            sx={{
              width: 200,
              height: 150,
              borderRadius: 1,
              backgroundColor: "red",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            company logo
          </Box>
        </Grid> */}
      </Stack>
    </Grid>
  );
}

export default BranchInformation;
