import React from "react";
import { useGetAllAssignmentQuery } from "../../app/api/users/userAssignment";
import {
  Paper,
  Table,
  TableCell,
  TableHead,
  Typography,
  TableBody,
  TableRow,
} from "@mui/material";

export default function PrintClient() {
  const { data, error, isLoading } = useGetAllAssignmentQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  let content = (
    <Paper elevation={3}>
      <Typography variant="h5" component="h2" sx={{ textAlign: "center" }}>
        Client List
      </Typography>
      <Table>
        <TableBody>
          <TableRow className="tableHead ">
            <TableCell sx={{ fontWeight: 700 }}>Assignment CODE</TableCell>
            <TableCell sx={{ fontWeight: 700 }}>Area Name</TableCell>
            <TableCell sx={{ fontWeight: 700 }}>Number of Assign</TableCell>
            <TableCell sx={{ fontWeight: 700 }}>Registered Name</TableCell>
            <TableCell sx={{ fontWeight: 700 }}>Address</TableCell>
            <TableCell sx={{ fontWeight: 700 }}>Pagibig</TableCell>
            <TableCell sx={{ fontWeight: 700 }}>Philhealth</TableCell>
            <TableCell sx={{ fontWeight: 700 }}>SSS</TableCell>
            <TableCell sx={{ fontWeight: 700 }}>Status</TableCell>
          </TableRow>
          {data?.map((assignment) => (
            <TableRow key={assignment.id}>
              <TableCell>{assignment?.code_no}</TableCell>
              <TableCell>{assignment.area_name}</TableCell>
              <TableCell>{assignment.countNumberOfAssign}</TableCell>
              <TableCell>{assignment.registered_name}</TableCell>
              <TableCell>{assignment.address}</TableCell>
              <TableCell>{assignment.pagibig}</TableCell>
              <TableCell>{assignment.philhealth}</TableCell>
              <TableCell>{assignment.sss}</TableCell>
              <TableCell>
                {assignment.status === 0 ? "Active" : "Inactive"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );

  return content;
}
