import { Paper, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ResponsiveList from "../../../components/list/ResponsiveList";
import { useBranchWidgetQuery } from "../../../app/api/reports/dashboardApiSlice";

function ClientWidget() {
  const { data, isLoading } = useBranchWidgetQuery({
    refetchOnMountOrArgChange: true,
  });
  const [branchCategory, setBranchCategory] = React.useState([]);

  useEffect(() => {
    if (data) {
      const categories = [];
      data.countUserPerBranch.forEach((branch) => {
        categories.push({
          title: branch.branch_name,
          value: branch.count,
        });
      });
      setBranchCategory(categories);
    }
  }, [data, isLoading]);
  console.log(branchCategory);
  return (
    <Paper elevation={3} sx={{ p: 2, height: "100%" }}>
      <Stack spacing={2}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            textAlign: "center",
            backgroundColor: "#E1F7F5",
            color: "black",
          }}
        >
          Client
        </Typography>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <ResponsiveList categories={branchCategory} type={0} />
        </Stack>
      </Stack>
    </Paper>
  );
}

export default ClientWidget;
