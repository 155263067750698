import { Box, Card, CardContent, ButtonBase, Typography } from "@mui/material";
import { ListAlt, Payment } from "@mui/icons-material";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import ReceiptIcon from "@mui/icons-material/Receipt";
import React from "react";

function PayrollMenu() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          width: "100%",
          height: "500px",
          alignItems: "center",
        }}
        className="administrative-card"
      >
        <ButtonBase
          href="/manage-payroll/create-payroll"
          sx={{
            textDecoration: "none",
            display: "flex",
            justifyContent: "center",
            width: "200px",
            height: "200px",
            marginRight: "10px",
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: 3,
            },
          }}
        >
          <Card
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <RequestQuoteIcon
                sx={{
                  fontSize: 50,
                  color: "primary.main",
                }}
              />
              <p style={{ margin: "0", fontWeight: 700 }}>Create Payroll </p>
            </CardContent>
          </Card>
        </ButtonBase>
        <ButtonBase
          href="/manage-payroll/payroll-group-list"
          sx={{
            textDecoration: "none",
            display: "flex",
            justifyContent: "center",
            width: "200px",
            height: "200px",
            marginRight: "10px",
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: 3,
            },
          }}
        >
          <Card
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <ListAlt
                sx={{
                  fontSize: 50,
                  color: "primary.main",
                }}
              />
              <p style={{ margin: "0", fontWeight: 700 }}>
                Payroll Group List{" "}
              </p>
            </CardContent>
          </Card>
        </ButtonBase>

        <ButtonBase
          href="/manage-payroll/payroll-list"
          sx={{
            textDecoration: "none",
            display: "flex",
            justifyContent: "center",
            width: "200px",
            height: "200px",
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: 3,
            },
            marginRight: "10px",
          }}
        >
          <Card
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <ReceiptIcon
                sx={{
                  fontSize: 50,
                  color: "primary.main",
                }}
              />
              <p style={{ margin: "0", fontWeight: 700 }}>Payroll List</p>
            </CardContent>
          </Card>
        </ButtonBase>

        <ButtonBase
          href="/manage-13th-month-pay"
          sx={{
            textDecoration: "none",
            display: "flex",
            justifyContent: "center",
            width: "200px",
            height: "200px",
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "scale(1.05)",
              boxShadow: 3,
            },
          }}
        >
          <Card
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Payment
                sx={{
                  fontSize: 50,
                  color: "primary.main",
                }}
              />
              <p style={{ margin: "0", fontWeight: 700 }}>
                13th Month Pay List
              </p>
            </CardContent>
          </Card>
        </ButtonBase>
      </Box>
    </>
  );
}

export default PayrollMenu;
