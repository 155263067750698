import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../apiSlice";

export const userAssignmentAdapter = createEntityAdapter();

const initialState = userAssignmentAdapter.getInitialState();

export const userAssignmentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserAssignment: builder.query({
      query: ({ branch_id, id }) => {
        return `/user-assignment/assignment?branch_id=${branch_id}&assignment_id=${id}`;
      },
      providesTags: (result, error, arg) => [
        { type: "UserAssignment", id: arg.branch_id },
      ],
    }),
    getAllAssignment: builder.query({
      query: () => "/assignment/all",
      providesTags: (result, error, arg) => [
        { type: "UserAssignment", id: "new" },
      ],
    }),

    getListAssignment: builder.query({
      query: ({ page, search, rowsPerPage }) => {
        const params = new URLSearchParams();
        if (page) params.append("page", page);
        if (search) params.append("search", search);
        if (rowsPerPage) params.append("rowPerPage", rowsPerPage);

        return `/assignment?${params.toString()}`;
      },
      providesTags: (result, error, arg) => [
        { type: "UserAssignment", id: "new" },
      ],
    }),
    addUserAssignment: builder.mutation({
      query: (formData) => {
        const headers =
          formData instanceof FormData
            ? {}
            : { "Content-Type": "application/json" };

        return {
          url: "/user-assignment",
          method: "POST",
          body: formData,
          headers,
        };
      },
      invalidatesTags: [{ type: "UserAssignment", id: "new" }],
    }),
    updateUserAssignment: builder.mutation({
      query: (formData) => {
        const headers =
          formData instanceof FormData
            ? {}
            : { "Content-Type": "application/json" };

        return {
          url: "/user-assignment",
          method: "PUT",
          body: formData,
          headers,
        };
      },
      invalidatesTags: [{ type: "UserAssignment", id: "new" }],
    }),
    deleteUserAssignment: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/user-assignment/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "UserAssignment", id: "new" }],
    }),
  }),
  overrideExisting: false,
  reducerPath: "userAssignmentApi",
  baseQuery: apiSlice.baseQuery,
  tagTypes: ["UserAssignment"],
  endpointsDefinitions: {
    query: {
      providesTags: (result, error, arg) => [
        { type: "UserAssignment", id: "new" },
      ],
    },
  },
});

export const {
  useGetUserAssignmentQuery,
  useAddUserAssignmentMutation,
  useUpdateUserAssignmentMutation,
  useDeleteUserAssignmentMutation,
  useGetListAssignmentQuery,
  useGetAllAssignmentQuery,
} = userAssignmentApiSlice;
