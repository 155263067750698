import {
  Box,
  Stack,
  Typography,
  TextField,
  Grid,
  Divider,
  Select,
  MenuItem,
  Paper,
  FormControl,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetAllAssignmentQuery } from "../../../../app/api/users/userAssignment";
import { useGetUserPositionQuery } from "../../../../app/api/users/userPosition";

function EmployeeAssignment({
  employeeAssignment,
  setEmployeeAssignment,
  type,
  formErrors,
}) {
  const [error, setError] = useState(false);
  const { data, isLoading } = useGetAllAssignmentQuery({
    refetchOnMountOrArgChange: true,
  });
  const [assignmentList, setAssignmentList] = useState([]);
  const { data: positionData } = useGetUserPositionQuery({
    refetchOnMountOrArgChange: true,
  });
  const [positionList, setPositionList] = useState([]);

  useEffect(() => {
    if (data) {
      const list = data
        .filter((item) => item.status === 0)
        .map((item) => ({
          id: item.id,
          name: item.area_name,
        }));
      setAssignmentList(list);
    }
  }, [data]);

  useEffect(() => {
    if (positionData) {
      const list = positionData.map((item) => ({
        id: item.id,
        name: item.position_name,
      }));
      setPositionList(list);
    }
    //console.log("positionData", positionData);
  }, [positionData]);

  const handleChange = (field, value) => {
    setEmployeeAssignment((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <Box
        component="form"
        validate="true"
        sx={{ mt: 2 }}
        className="memberFormData"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
            <Typography variant="h6" sx={{ fontWeight: 700 }} gutterBottom>
              EMPLOYEE ASSIGNMENT
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              spacing={1}
              direction={{ xs: "column", md: "row" }}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="div">Position:</Typography>
              {type === "add" || type === "edit" ? (
                <FormControl
                  variant="standard"
                  fullWidth
                  style={{ marginTop: "0px !important;" }}
                  error={!!formErrors.position}
                  helperText={formErrors.position}
                >
                  <InputLabel
                    id="demo-simple-select-standard-label"
                    style={{ marginTop: "-16px" }}
                  >
                    Position
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={employeeAssignment.position}
                    onChange={(e) => handleChange("position", e.target.value)}
                    label="Position"
                    className="positionSelect"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {positionList.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <Typography
                  variant="div"
                  sx={{ fontWeight: 700, textAlign: "center" }}
                >
                  {
                    positionList.find(
                      (item) => item.id === employeeAssignment.position
                    )?.name
                  }
                </Typography>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              spacing={1}
              direction={{ xs: "column", md: "row" }}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="div">Assignment:</Typography>
              {type === "add" || type === "edit" ? (
                <FormControl
                  variant="standard"
                  fullWidth
                  error={!!formErrors.assignment}
                  helperText={formErrors.assignment}
                >
                  <InputLabel
                    id="demo-simple-select-standard-label"
                    style={{ marginTop: "-16px" }}
                  >
                    Assignment
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={employeeAssignment.assignment}
                    onChange={(e) => handleChange("assignment", e.target.value)}
                    label="Assignment"
                    className="positionSelect"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {assignmentList.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <Typography
                  variant="div"
                  sx={{ fontWeight: 700, textAlign: "center" }}
                >
                  {
                    assignmentList.find(
                      (item) => item.id === employeeAssignment.assignment
                    )?.name
                  }
                </Typography>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              spacing={1}
              direction={{ xs: "column", md: "row" }}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="div">Remarks:</Typography>
              {type === "add" || type === "edit" ? (
                <TextField
                  label=""
                  type="text"
                  style={{ width: "100%" }}
                  error={error}
                  value={employeeAssignment.remarks}
                  onChange={(e) => handleChange("remarks", e.target.value)}
                  variant="standard"
                  helperText={error ? "Please input Remarks" : ""}
                />
              ) : (
                <Typography
                  variant="div"
                  sx={{ fontWeight: 700, textAlign: "center" }}
                >
                  {employeeAssignment.remarks}
                </Typography>
              )}
            </Stack>
          </Grid>
          <Divider />
          <Grid item xs={12} md={4}>
            <Stack
              spacing={1}
              direction={{ xs: "column", md: "row" }}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="div" sx={{ width: "200px" }}>
                Date Hired:
              </Typography>
              {type === "add" || type === "edit" ? (
                <TextField
                  label=""
                  type="date"
                  style={{ width: "100%" }}
                  error={!!formErrors.dateHired}
                  value={employeeAssignment.dateHired}
                  onChange={(e) => handleChange("dateHired", e.target.value)}
                  variant="standard"
                  helperText={formErrors.dateHired}
                />
              ) : (
                <Typography
                  variant="div"
                  sx={{ fontWeight: 700, textAlign: "center" }}
                >
                  {employeeAssignment.dateHired}
                </Typography>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              spacing={1}
              direction={{ xs: "column", md: "row" }}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="div" sx={{ width: "280px" }}>
                Date End Contract:
              </Typography>
              {type === "add" || type === "edit" ? (
                <TextField
                  label=""
                  type="date"
                  style={{ width: "100%" }}
                  error={!!formErrors.dateEndContract}
                  value={employeeAssignment.dateEndContract}
                  onChange={(e) =>
                    handleChange("dateEndContract", e.target.value)
                  }
                  variant="standard"
                  helperText={formErrors.dateEndContract}
                />
              ) : (
                <Typography
                  variant="div"
                  sx={{ fontWeight: 700, textAlign: "center" }}
                >
                  {employeeAssignment.dateEndContract}
                </Typography>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              spacing={1}
              direction={{ xs: "column", md: "row" }}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="div">Remarks:</Typography>
              {type === "add" || type === "edit" ? (
                <TextField
                  label=""
                  type="text"
                  style={{ width: "100%" }}
                  error={error}
                  value={employeeAssignment.remarksEndContract}
                  onChange={(e) =>
                    handleChange("remarksEndContract", e.target.value)
                  }
                  variant="standard"
                  helperText={error ? "Please input remarks" : ""}
                />
              ) : (
                <Typography
                  variant="div"
                  sx={{ fontWeight: 700, textAlign: "center" }}
                >
                  {employeeAssignment.remarksEndContract}
                </Typography>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              spacing={1}
              direction={{ xs: "column", md: "row" }}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="div" sx={{ width: "250px" }}>
                Training Completion:
              </Typography>
              {type === "add" || type === "edit" ? (
                <TextField
                  label=""
                  type="date"
                  style={{ width: "100%" }}
                  error={error}
                  value={employeeAssignment.trainingCompletion}
                  onChange={(e) =>
                    handleChange("trainingCompletion", e.target.value)
                  }
                  variant="standard"
                  helperText={error ? "Please select date" : ""}
                />
              ) : (
                <Typography
                  variant="div"
                  sx={{ fontWeight: 700, textAlign: "center" }}
                >
                  {employeeAssignment.trainingCompletion}
                </Typography>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack
              spacing={1}
              direction={{ xs: "column", md: "row" }}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="div" sx={{ width: "200px" }}>
                Hours Completed:
              </Typography>
              {type === "add" || type === "edit" ? (
                <TextField
                  label=""
                  type="text"
                  style={{ width: "100%" }}
                  error={error}
                  value={employeeAssignment.hoursCompleted}
                  onChange={(e) =>
                    handleChange("hoursCompleted", e.target.value)
                  }
                  variant="standard"
                  helperText={error ? "Please input hours" : ""}
                />
              ) : (
                <Typography
                  variant="div"
                  sx={{ fontWeight: 700, textAlign: "center" }}
                >
                  {employeeAssignment.hoursCompleted}
                </Typography>
              )}
            </Stack>
          </Grid>
          <Divider />
          <Grid item xs={12} md={6}>
            <Stack
              spacing={1}
              direction={{ xs: "column", md: "row" }}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="div">Duration:</Typography>
              {type === "add" || type === "edit" ? (
                <Select
                  label=""
                  type="text"
                  style={{ width: "100%" }}
                  error={!!formErrors.duration}
                  helperText={formErrors.duration}
                  value={employeeAssignment.duration}
                  onChange={(e) => handleChange("duration", e.target.value)}
                  variant="standard"
                >
                  <MenuItem value={1}>6 Month</MenuItem>
                  <MenuItem value={2}>1 Year</MenuItem>
                  <MenuItem value={3}>3 Years</MenuItem>
                </Select>
              ) : (
                <Typography
                  variant="div"
                  sx={{ fontWeight: 700, textAlign: "center" }}
                >
                  {employeeAssignment.duration === 1
                    ? "6 Month"
                    : employeeAssignment.duration === 2
                    ? "1 Year"
                    : "3 Years"}
                </Typography>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              spacing={1}
              direction={{ xs: "column", md: "row" }}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="div" sx={{ width: "150px" }}>
                Work Status:
              </Typography>
              {type === "add" || type === "edit" ? (
                <Select
                  label=""
                  type="text"
                  style={{ width: "100%" }}
                  error={!!formErrors.workStatus}
                  value={employeeAssignment.workStatus}
                  onChange={(e) => handleChange("workStatus", e.target.value)}
                  variant="standard"
                  helperText={formErrors.workStatus}
                >
                  <MenuItem value={1}>Regular</MenuItem>
                  <MenuItem value={2}>OJT</MenuItem>
                  <MenuItem value={3}>Contractual</MenuItem>
                  <MenuItem value={4}>Casual</MenuItem>
                  <MenuItem value={5}>Part Time</MenuItem>
                </Select>
              ) : (
                <Typography
                  variant="div"
                  sx={{ fontWeight: 700, textAlign: "center" }}
                >
                  {employeeAssignment.workStatus === 1
                    ? "Regular"
                    : employeeAssignment.workStatus === 2
                    ? "OJT"
                    : employeeAssignment.workStatus === 3
                    ? "Contractual"
                    : employeeAssignment.workStatus === 4
                    ? "Casual"
                    : "Part Time"}
                </Typography>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

export default EmployeeAssignment;
