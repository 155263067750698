import { useLocation, Navigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";

const RequireAuth = ({ children, allowedRoles }) => {
  const { roles, username } = useAuth();
  const location = useLocation();
  if (username) {
    if (roles.some((role) => allowedRoles.includes(role))) {
      return children;
    } else {
      if (roles.includes(100)) {
        return (
          <Navigate
            to="/manage-payroll/create-payroll"
            state={{ from: location }}
            replace
          />
        );
      } else if (roles.includes(999)) {
        return <Navigate to="/dashboard" state={{ from: location }} replace />;
      } else {
        return <Navigate to="/login" state={{ from: location }} replace />;
      }
    }
  } else {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
};
export default RequireAuth;
