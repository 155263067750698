import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../apiSlice";

export const userPermissionAdapter = createEntityAdapter();

const initialState = userPermissionAdapter.getInitialState();

export const userPermissionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserPermission: builder.query({
      query: ({ id }) => {
        const params = new URLSearchParams();
        if (id) params.append("id", id);

        return `/permission?${params.toString()}`;
      },

      providesTags: (result, error, arg) => [
        { type: "UserPermission", id: "new" },
      ],
    }),
    addUserPermission: builder.mutation({
      query: (formData) => {
        const headers =
          formData instanceof FormData
            ? {}
            : { "Content-Type": "application/json" };

        return {
          url: "/permission",
          method: "POST",
          body: formData,
          headers,
        };
      },
      invalidatesTags: [{ type: "UserPermission", id: "new" }],
    }),
    updateUserPermission: builder.mutation({
      query: (formData) => {
        const headers =
          formData instanceof FormData
            ? {}
            : { "Content-Type": "application/json" };

        return {
          url: "/permission",
          method: "PUT",
          body: formData,
          headers,
        };
      },
      invalidatesTags: [{ type: "UserPermission", id: "new" }],
    }),
    deleteUserPermission: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/permission/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "UserPermission", id: "new" }],
    }),
  }),
});

export const {
  useGetUserPermissionQuery,
  useAddUserPermissionMutation,
  useUpdateUserPermissionMutation,
  useDeleteUserPermissionMutation,
} = userPermissionApiSlice;
