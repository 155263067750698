import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import PayrollData from "./PayrollData";
import PayrollForms from "./PayrollForms";
import CreatePayroll from "./pages/CreatePayroll";
import CreatePayrollN from "./pages/CreatePayrollN";
import PaySlip from "./pages/PaySlip";
import ViewPayroll from "./pages/ViewPayroll";
import Create13thMonth from "./pages/Create13thMonth";
import PayrollDataN from "./PayrollDataN";
import PayrollMenu from "./PayrollMenu";

function Payroll() {
  const { id, type } = useParams();
  const navigate = useNavigate();
  let content = null;
  switch (type) {
    case "edit":
      navigate("/manage-payroll/edit/" + id);
      break;
    case "add":
      content = <PayrollForms />;
      break;
    case "view-payslip":
      content = <PaySlip />;
      break;
    case "payroll-list":
      content = <PayrollDataN />;
      break;
    case "payroll-group-list":
      content = <PayrollData />;
      break;
    case "create-payroll":
      content = <CreatePayrollN type={"Create"} />;
      break;
    case "view-payroll":
      content = <ViewPayroll />;
      break;
    case "create-13th-month":
      content = <Create13thMonth />;
      break;
    case "edit-payroll":
      content = <CreatePayrollN type={"Edit"} />;
      break;
    default:
      content = <PayrollMenu />;
      break;
  }
  return content;
}

export default Payroll;
