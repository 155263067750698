import * as React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import GrainIcon from "@mui/icons-material/Grain";
import Box from "@mui/material/Box";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function IconBreadcrumbs({ data, title }) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", mb: 1 }}>
      <Typography
        variant="h6"
        sx={{ fontWeight: 700, fontSize: 26, color: "#5EADA8" }}
      >
        {title}
      </Typography>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          {data.map((item, index) => (
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}
              color="inherit"
              href={item.link}
            >
              {index === 0 ? (
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              ) : index === 1 ? (
                <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              ) : (
                <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              )}
              {item.title}
            </Link>
          ))}
        </Breadcrumbs>
      </div>
    </Box>
  );
}
