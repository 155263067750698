import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Typography,
} from "@mui/material";

import {
  useGetStaffQuery,
  useDeleteStaffMutation,
} from "../../../../app/api/users/userStaff";
import useAuth from "../../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import DISTable from "../../../../components/tables/DISTable";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import CustomizedMenus from "../../../../components/buttons/CustomizeMenu";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AlertDialog from "../../../../components/modal/AlertDialog";
import IconBreadcrumbs from "../../../../components/shared/Breadcrumb";

const headers = [
  {
    id: "fullname",
    label: "Full Name",
    sortable: true,
    searchable: true,
  },
  {
    id: "username",
    label: "Username",
    sortable: true,
  },
  {
    id: "password",
    label: "Password",
  },
  {
    id: "position",
    label: "Role",
    sortable: true,
    filterable: true, // Enabling filter for this field
  },
  {
    id: "status",
    label: "Work Status",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

function EmployeeDataN() {
  const { branch_no, isSuperAdmin } = useAuth();
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogId, setDialogId] = useState("");
  const [clientStatus, setClientStatus] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [totalPageNumber, setTotalPageNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [deleteStaff] = useDeleteStaffMutation();

  const { data: getStaffData } = useGetStaffQuery(
    {
      ...(isSuperAdmin === true ? { branch_no: branch_no } : {}),
      page: page + 1,
      search: searchValue,
      rowsPerPage: rowsPerPage,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const navigate = useNavigate();

  const options = {
    allowEdit: true,
    allowDelete: true,
    allowView: true,
    allowAdd: true,
    allowImport: true,
    allowExport: true,
    allowSearch: true,
    numberPerPage: 10,
    selectable: false,
    page: setPage,
    totalPageNumber: 0,
    tableTitle: "",
    isLoading: isLoading,
    setSearchValue: setSearchValue,
    searchValue: searchValue,
    setIsLoading: setIsLoading,
    setRowsPerPage: setRowsPerPage,
  };

  useEffect(() => {
    if (getStaffData) {
      let users = getStaffData[0].data.map((user) => {
        return {
          id: user._id,
          fullname: `${user.lastname}, ${user.firstname} ${user.middlename}`,
          username: user.username,
          password: user.original_password,
          position:
            user.roles.User === 1002
              ? "HR"
              : user.roles.User === 100
              ? "Encoder"
              : "Admin",
          status: user.status === 0 ? "Active" : "Inactive",
        };
      });
      setTotalPageNumber(getStaffData[0].totalCount[0]?.count || 0);
      setUsers(users);
    }
  }, [getStaffData, page]);

  options.totalPageNumber = totalPageNumber;
  const handleEdit = (id) => {
    console.log(id);
    //n//avigate(`/manage-password/view/${id}`);
  };

  const handleSwitchOnOff = (id, status) => {
    setDialogOpen(true);
    setDialogId(id);
    setClientStatus(status);
    setDialogMessage(
      status === "Active"
        ? "Are you sure you want to deactivate this client?"
        : "Are you sure you want to activate this client?"
    );
  };

  const handleView = (id) => {
    navigate(`/manage-password/view/${id}`);
  };

  const customButton = (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate("/manage-password/create-user")}
        sx={{
          mr: 1,
          width: { xs: "100%", sm: "200px" }, // Full width on small screens and 200px on larger
          minHeight: "38px",
        }}
      >
        Add Accounts
      </Button>
    </>
  );

  const handleYesDelete = async (id) => {
    try {
      const res = await deleteStaff({
        id: dialogId,
        status: clientStatus === "Active" ? 1 : 0,
        type: "staff",
      }).unwrap();
      if (res) {
        toast.success("Staff deleted successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const actions = {
    //handleEdit,
    handleView,
    //handleDelete,
    handleSwitchOnOff,
  };

  return (
    <>
      <IconBreadcrumbs
        data={[
          { link: "/dashboard", title: "Home" },
          { link: "/administrative", title: "Administrative" },
          { link: "/administrative/manage-users", title: "Manage Accounts" },
        ]}
        title="Manage Accounts"
      />
      <DISTable
        headers={headers}
        data={users}
        options={options}
        actions={actions}
        customButton={customButton}
      />
      <AlertDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        title="Update Status"
        content={dialogMessage}
        dialogId={dialogId}
        action={handleYesDelete}
      />
      <ToastContainer />
    </>
  );
}

export default EmployeeDataN;
