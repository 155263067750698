import { Paper, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ResponsiveList from "../../../components/list/ResponsiveList";
import { useGenderWidgetQuery } from "../../../app/api/reports/dashboardApiSlice";
import useAuth from "../../../hooks/useAuth";

function GenderWidget() {
  const { dashboard, branch_no } = useAuth();

  const [catData, setCatData] = React.useState({
    maleCount: 0,
    femaleCount: 0,
  });

  const { data, isLoading } = useGenderWidgetQuery(
    { ...(dashboard === true ? { branch_no: branch_no } : {}) },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (data) {
      setCatData({
        maleCount: data.maleCount,
        femaleCount: data.femaleCount,
      });
    }
  }, [data, isLoading]);

  const categories = [
    { title: "Male", value: catData.maleCount },
    { title: "Female", value: catData.femaleCount },
  ];
  return (
    <Paper elevation={3} sx={{ p: 2, height: "100%" }}>
      <Stack spacing={2}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            textAlign: "center",
            backgroundColor: "#E1F7F5",
            color: "black",
          }}
        >
          GENDER
        </Typography>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <ResponsiveList categories={categories} />
        </Stack>
      </Stack>
    </Paper>
  );
}

export default GenderWidget;
