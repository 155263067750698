import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../apiSlice";

export const userStaffAdapter = createEntityAdapter();

const initialState = userStaffAdapter.getInitialState();

const userStaffApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStaff: builder.query({
      query: ({ branch_no, page, rowsPerPage, search }) => {
        const params = new URLSearchParams();
        if (page) params.append("page", page);
        if (rowsPerPage) params.append("rowsPerPage", rowsPerPage);
        if (search) params.append("search", search);
        if (branch_no) params.append("branch_no", branch_no);

        return `/staff?${params.toString()}`;
      },
      providesTags: [{ type: "UserStaff", id: "Staff" }],
    }),
    getStaffById: builder.query({
      query: ({ id }) => `/staff/${id}`,
      providesTags: [{ type: "UserStaff", id: "Staff" }],
    }),
    addStaff: builder.mutation({
      query: (formData) => ({
        url: "/staff",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: [{ type: "UserStaff", id: "Staff" }],
    }),
    updateStaff: builder.mutation({
      query: (formData) => ({
        url: "/staff",
        method: "PUT",
        body: formData,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "UserStaff", id: "Staff" }],
    }),
    deleteStaff: builder.mutation({
      query: (formData) => ({
        url: `/staff/delete`,
        method: "PUT",
        body: formData,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "UserStaff", id: "Staff" }],
    }),
  }),
});

export const {
  useGetStaffQuery,
  useGetStaffByIdQuery,
  useAddStaffMutation,
  useUpdateStaffMutation,
  useDeleteStaffMutation,
} = userStaffApiSlice;
