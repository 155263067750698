import BirthdayWidget from "../../../features/admin/widgets/BirthdayWidget";
import GenderWidget from "../../../features/admin/widgets/GenderWidget";
import { apiSlice } from "../apiSlice";

export const dashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    employeeWidget: builder.query({
      query: ({ branch_no }) => {
        const params = new URLSearchParams();
        if (branch_no) params.append("branch_no", branch_no);
        return `/dashboard/employee?${params.toString()}`;
      },
    }),
    branchWidget: builder.query({
      query: () => "/dashboard/branch",
    }),
    BirthdayWidget: builder.query({
      query: ({ branch_no, isAdmin, isHr }) => {
        const params = new URLSearchParams();
        if (branch_no) params.append("branch_no", branch_no);
        if (isAdmin) params.append("isAdmin", isAdmin);
        if (isHr) params.append("isHr", isHr);
        return `/dashboard/birthdays?${params.toString()}`;
      },
    }),
    GenderWidget: builder.query({
      query: ({ branch_no }) => {
        const params = new URLSearchParams();
        if (branch_no) params.append("branch_no", branch_no);
        return `/dashboard/genderCount?${params.toString()}`;
      },
    }),

    workTypeWidget: builder.query({
      query: ({ branch_no }) => {
        const params = new URLSearchParams();
        if (branch_no) params.append("branch_no", branch_no);
        return `/dashboard/workType?${params.toString()}`;
      },
    }),

    expiredLicenseWidget: builder.query({
      query: ({ branch_no }) => {
        const params = new URLSearchParams();
        if (branch_no) params.append("branch_no", branch_no);
        return `/dashboard/expiredLicenses?${params.toString()}`;
      },
    }),
  }),
});

export const {
  useEmployeeWidgetQuery,
  useBranchWidgetQuery,
  useBirthdayWidgetQuery,
  useGenderWidgetQuery,
  useWorkTypeWidgetQuery,
  useExpiredLicenseWidgetQuery,
} = dashboardApiSlice;
