import {
  Button,
  Grid,
  Paper,
  Stack,
  TextField,
  Select,
  MenuItem,
  Typography,
  Box,
  FormGroup,
  Checkbox,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  useAddStaffMutation,
  useGetStaffByIdQuery,
  useUpdateStaffMutation,
} from "../../../../app/api/users/userStaff";
import useAuth from "../../../../hooks/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Dashboard } from "@mui/icons-material";

function CreateUser() {
  const [roleSelected, setRoleSelected] = useState([]);
  const { branch_no, id } = useAuth();
  const { id: userId, type } = useParams();
  const [selectedValue, setSelectedValue] = useState("0");
  const [error, setError] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [updateStaff, { isLoading: isUpdating }] = useUpdateStaffMutation();
  const [inputValue, setInputValue] = useState({
    username: "",
    password: "",
    Cpassword: "",
    firstName: "",
    middleName: "",
    lastName: "",
    suffix: "",
    email: "",
    userType: 2,
    role: 0,
    manageAccounts: { view: false, add: false, edit: false, delete: false },
    manageClients: { view: false, add: false, edit: false, delete: false },
    permissions: { isAdmin: false },
    dashboard: { allowView: false },
  });

  const navigate = useNavigate();
  const [addStaff, { isLoading: isAdding }] = useAddStaffMutation();
  const { data: staffData, isLoading: isStaffLoading } = useGetStaffByIdQuery(
    { id: userId },
    {
      refetchOnMountOrArgChange: true,
      skip: type === "create-user",
    }
  );
  useEffect(() => {
    if (
      (staffData && !isStaffLoading && type === "edit") ||
      (staffData && type === "view")
    ) {
      setInputValue({
        username: staffData.username || "",
        password: staffData.original_password || "",
        Cpassword: staffData.original_password || "",
        firstName: staffData.firstname || "",
        middleName: staffData.middlename || "",
        lastName: staffData.lastname || "",
        suffix: staffData.suffix || "",
        email: staffData.email || "",
        userType: staffData.status, // Adding || "" for safety
        role: (staffData.roles && staffData.roles.User) || "", // Check if roles and roles.User are defined
      });

      setRoleSelected(staffData.roles.User);
    }
  }, [staffData, isStaffLoading, type]);

  const handlePasswordChange = (e) => {
    const { id, value } = e.target;
    setInputValue((prev) => ({ ...prev, [id]: value }));

    if (id === "Cpassword" || id === "password") {
      if (id === "Cpassword" && value !== inputValue.password) {
        setError("Passwords don't match");
      } else if (id === "password" && value !== inputValue.Cpassword) {
        setError("Passwords don't match");
      } else {
        setError("");
      }
    }
  };

  const handleRoleChange = (value) => {
    setRoleSelected(value);
    setInputValue({ ...inputValue, role: value });
  };

  const handleCheckValue = (name, subname) => (e) => {
    setInputValue({
      ...inputValue,
      [name]: { ...inputValue[name], [subname]: e.target.checked },
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!inputValue.username) errors.username = "Username is required";
    if (!inputValue.firstName) errors.firstName = "First name is required";
    if (!inputValue.lastName) errors.lastName = "Last name is required";
    if (!inputValue.password) errors.password = "Password is required";
    if (inputValue.password !== inputValue.Cpassword)
      errors.Cpassword = "Passwords do not match";
    if (!inputValue.role) errors.role = "Role is required";
    //if (!inputValue.userType) errors.status = "User status is required";
    /*if (!inputValue.email || !/\S+@\S+\.\S+/.test(inputValue.email))
      errors.email = "Valid email is required";*/
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleCreateStaff = async () => {
    if (!validateForm()) return;

    try {
      const addNewStaff = await addStaff({
        username: inputValue.username,
        password: inputValue.password,
        first_name: inputValue.firstName,
        middle_name: inputValue.middleName,
        last_name: inputValue.lastName,
        suffix: inputValue.suffix,
        email: inputValue.email,
        user_type: inputValue.userType,
        role: inputValue.role,
        branch_id: branch_no,
        added_by: id,
        manageAccounts: inputValue.manageAccounts,
        manageClients: inputValue.manageClients,
        permissions: inputValue.permissions,
        dashboard: inputValue.dashboard,
      });

      if (addNewStaff?.data?.id) {
        toast.success(addNewStaff.data.message);
        navigate("/manage-password/view/" + addNewStaff.data.id);
      } else {
        setFormErrors({ username: addNewStaff.error.data.message });
        toast.error(addNewStaff.error.data.message);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || // For Axios or API errors
        error.message || // For standard JavaScript errors
        "An unknown error occurred"; // Fallback message

      toast.error(errorMessage);
      console.error(error); // Use console.error for more visibility in debugging
    }
  };

  const handleUpdateStaff = () => {
    if (!validateForm()) return;

    try {
      const updated = updateStaff({
        user_id: userId,
        username: inputValue.username,
        password: inputValue.password,
        first_name: inputValue.firstName,
        middle_name: inputValue.middleName,
        last_name: inputValue.lastName,
        suffix: inputValue.suffix,
        email: inputValue.email,
        user_type: inputValue.userType,
        role: inputValue.role,
        branch_id: branch_no,
        updated_by: id,
        manageAccounts: inputValue.manageAccounts,
        manageClients: inputValue.manageClients,
        permissions: inputValue.permissions,
        dashboard: inputValue.dashboard,
      });
      if (updated) {
        toast.success("Staff updated successfully");
        navigate("/manage-password/view/" + userId);
      }
    } catch (error) {
      toast.error("An error occurred");
      console.log(error);
    }
  };

  const handleBack = () => {
    navigate("/administrative/manage-users");
  };

  const handleEdit = () => {
    navigate("/manage-password/edit/" + userId);
  };

  let showPermissions = (
    <Grid item md={12} lg={12} sx={{ margin: 1 }}>
      <FormGroup>
        <Stack direction={"row"}>
          <Stack direction={"column"}>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={handleCheckValue("permissions", "isAdmin")}
                  disabled={type === "view"}
                />
              }
              label="isSuperAdmin"
            />
          </Stack>
          <Stack direction={"column"}>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={handleCheckValue("manageAccounts", "view")}
                  disabled={type === "view"}
                />
              }
              label="Manage Accounts"
            />
            <Stack direction={"column"} spacing={0} sx={{ ml: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={handleCheckValue("manageAccounts", "add")}
                    disabled={type === "view"}
                  />
                }
                label="Add"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={handleCheckValue("manageAccounts", "edit")}
                    disabled={type === "view"}
                  />
                }
                label="Edit"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={handleCheckValue("manageAccounts", "delete")}
                    disabled={type === "view"}
                  />
                }
                label="Delete"
              />
            </Stack>
          </Stack>
          <Stack direction={"column"}>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={handleCheckValue("manageClients", "view")}
                  disabled={type === "view"}
                />
              }
              label="Manage Clients"
            />
            <Stack direction={"column"} spacing={0} sx={{ ml: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={handleCheckValue("manageClients", "add")}
                    disabled={type === "view"}
                  />
                }
                label="Add"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={handleCheckValue("manageClients", "edit")}
                    disabled={type === "view"}
                  />
                }
                label="Edit"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={handleCheckValue("manageClients", "delete")}
                    disabled={type === "view"}
                  />
                }
                label="Delete"
              />
            </Stack>
          </Stack>
          <Stack direction={"column"}>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={handleCheckValue("dashboard", "allowView")}
                  disabled={type === "view"}
                />
              }
              label="Dashboard"
            />
          </Stack>
        </Stack>
      </FormGroup>
    </Grid>
  );

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          p: 2,
          overflowX: "auto",
          backgroundColor: "#D1F3F1",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          md={8}
          lg={10}
          sx={{
            padding: "20px 15px",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
          }}
        >
          <Grid
            item
            md={11.5}
            lg={11.5}
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                mb: 2,
              }}
            >
              <Button variant="contained" color="primary" onClick={handleBack}>
                Back
              </Button>
              {type === "edit" || type === "create-user" ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={
                    type === "edit" ? handleUpdateStaff : handleCreateStaff
                  }
                  disabled={isAdding}
                  type="submit"
                >
                  {type === "edit" ? "Update" : "Create"} Staff
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleEdit}
                >
                  Edit
                </Button>
              )}
            </Box>

            {/* User Credentials */}
            <fieldset>
              <legend>User Credentials</legend>
              <Stack direction="column" spacing={1}>
                <TextField
                  fullWidth
                  id="username"
                  label="Username"
                  variant="outlined"
                  required
                  value={inputValue.username}
                  onChange={(e) =>
                    setInputValue({ ...inputValue, username: e.target.value })
                  }
                  error={!!formErrors.username}
                  helperText={formErrors.username}
                  disabled={type === "view"}
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "black",
                    },
                    "& .MuiOutlinedInput-input": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                />
                <TextField
                  fullWidth
                  id="password"
                  label="Password"
                  variant="outlined"
                  type="password"
                  required
                  value={inputValue.password}
                  onChange={handlePasswordChange}
                  error={!!formErrors.password || !!error}
                  helperText={formErrors.password || error}
                  disabled={type === "view"}
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "black",
                    },
                    "& .MuiOutlinedInput-input": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                />
                <TextField
                  fullWidth
                  id="Cpassword"
                  label="Confirm Password"
                  variant="outlined"
                  type="password"
                  required
                  value={inputValue.Cpassword}
                  onChange={handlePasswordChange}
                  error={!!formErrors.Cpassword}
                  helperText={formErrors.Cpassword}
                  disabled={type === "view"}
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "black",
                    },
                    "& .MuiOutlinedInput-input": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                />
              </Stack>
            </fieldset>

            {/* User Information */}
            <fieldset>
              <legend>User Information</legend>
              <Stack direction="column" spacing={1}>
                <TextField
                  fullWidth
                  id="firstname"
                  label="First Name"
                  variant="outlined"
                  required
                  value={inputValue.firstName}
                  onChange={(e) =>
                    setInputValue({ ...inputValue, firstName: e.target.value })
                  }
                  error={!!formErrors.firstName}
                  helperText={formErrors.firstName}
                  disabled={type === "view"}
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "black",
                    },
                    "& .MuiOutlinedInput-input": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                />
                <TextField
                  fullWidth
                  id="lastname"
                  label="Last Name"
                  variant="outlined"
                  required
                  value={inputValue.lastName}
                  onChange={(e) =>
                    setInputValue({ ...inputValue, lastName: e.target.value })
                  }
                  error={!!formErrors.lastName}
                  helperText={formErrors.lastName}
                  disabled={type === "view"}
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "black",
                    },
                    "& .MuiOutlinedInput-input": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                />
                <TextField
                  fullWidth
                  id="email"
                  label="Email Address"
                  variant="outlined"
                  type="email"
                  value={inputValue.email}
                  onChange={(e) =>
                    setInputValue({ ...inputValue, email: e.target.value })
                  }
                  error={!!formErrors.email}
                  helperText={formErrors.email}
                  disabled={type === "view"}
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "black",
                    },
                    "& .MuiOutlinedInput-input": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                />
              </Stack>
            </fieldset>
            <fieldset>
              <legend>User Profile Attributes</legend>
              <Stack direction="column" spacing={1}>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item md={12} lg={5.8} sx={{ margin: 1 }}>
                    <Select
                      value={inputValue.role}
                      disabled={type === "view"}
                      onChange={(e) => handleRoleChange(e.target.value)}
                      sx={{
                        width: "100%",
                        "& .MuiInputLabel-root": {
                          color: "black",
                        },
                        "& .MuiOutlinedInput-input": {
                          WebkitTextFillColor: "black",
                        },
                      }}
                      error={!!formErrors.role}
                      helperText={formErrors.role}
                    >
                      <MenuItem value="0" disabled>
                        Role
                      </MenuItem>
                      <MenuItem value="999">Admin</MenuItem>
                      <MenuItem value="1002">HR</MenuItem>
                      <MenuItem value="200">Encoder</MenuItem>
                      <MenuItem value="100">Payroll Clerk</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item md={12} lg={5.8} sx={{ margin: 1 }}>
                    <Select
                      value={inputValue.userType}
                      disabled={type === "view"}
                      onChange={(e) =>
                        setInputValue({
                          ...inputValue,
                          userType: e.target.value,
                        })
                      }
                      sx={{
                        width: "100%",
                        "& .MuiInputLabel-root": {
                          color: "black",
                        },
                        "& .MuiOutlinedInput-input": {
                          WebkitTextFillColor: "black",
                        },
                      }}
                      error={!!formErrors.status}
                      helperText={formErrors.status}
                    >
                      <MenuItem value="2" disabled>
                        User Status
                      </MenuItem>
                      <MenuItem value="0">Active</MenuItem>
                      <MenuItem value="1">Inactive</MenuItem>
                    </Select>
                  </Grid>
                  {parseInt(roleSelected) === 1002 ||
                  parseInt(roleSelected) === 999
                    ? showPermissions
                    : ""}
                </Grid>
              </Stack>
            </fieldset>
          </Grid>
        </Grid>
      </Paper>
      <ToastContainer />
    </>
  );
}

export default CreateUser;
