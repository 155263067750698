import { Box, Stack, Typography, TextField, Grid, Paper } from "@mui/material";
import React, { useState } from "react";
import "./personal.css";

function EducationalBackground(data) {
  const [error, setError] = useState(false);
  const handleChange = (type, field, value) => {
    data.setEducationalBackground((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        [field]: value,
      },
    }));
  };
  return (
    <Paper elevation={3} sx={{ p: 2, mt: 2 }}>
      <Box
        component="form"
        validate="true"
        sx={{ mt: 1 }}
        className="memberFormData"
      >
        <Typography variant="h6" sx={{ fontWeight: 700 }} mb={2} gutterBottom>
          EDUCATIONAL BACKGROUND
        </Typography>
        <Grid container spacing={2} className="personal-info-grid">
          <Grid item md={5}>
            <Stack direction={"column"} spacing={1}>
              <Stack direction={"row"} spacing={2}>
                <Typography sx={{ width: "150px" }} variant="div">
                  Elementary:
                </Typography>
                {data.type === "add" || data.type === "edit" ? (
                  <TextField
                    label="Elementary School"
                    type="text"
                    style={{ width: "100%" }}
                    error={error}
                    value={data.educationalBackground.elementary.school_name}
                    onChange={(e) =>
                      handleChange("elementary", "school_name", e.target.value)
                    }
                    helperText={error ? "Please input elementary school" : ""}
                  />
                ) : (
                  <Typography sx={{ width: "150px" }} variant="div">
                    {data.educationalBackground.elementary.school_name}
                  </Typography>
                )}
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <Typography sx={{ width: "150px" }} variant="div">
                  High School:
                </Typography>
                {data.type === "add" || data.type === "edit" ? (
                  <TextField
                    label="high School"
                    type="text"
                    style={{ width: "100%" }}
                    error={error}
                    value={data.educationalBackground.high_school.school_name}
                    onChange={(e) =>
                      handleChange("high_school", "school_name", e.target.value)
                    }
                    helperText={error ? "Please input High school" : ""}
                  />
                ) : (
                  <Typography sx={{ width: "150px" }} variant="div">
                    {data.educationalBackground.high_school.school_name}
                  </Typography>
                )}
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <Typography sx={{ width: "150px" }} variant="div">
                  College:
                </Typography>
                {data.type === "add" || data.type === "edit" ? (
                  <TextField
                    label="college"
                    type="text"
                    style={{ width: "100%" }}
                    error={error}
                    value={data.educationalBackground.college.school_name}
                    onChange={(e) =>
                      handleChange("college", "school_name", e.target.value)
                    }
                    helperText={error ? "Please input college school" : ""}
                  />
                ) : (
                  <Typography sx={{ width: "150px" }} variant="div">
                    {data.educationalBackground.college.school_name}
                  </Typography>
                )}
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <Typography sx={{ width: "150px" }} variant="div">
                  Course:
                </Typography>
                {data.type === "add" || data.type === "edit" ? (
                  <TextField
                    label="Course"
                    type="text"
                    style={{ width: "100%" }}
                    error={error}
                    value={data.educationalBackground.college.course}
                    onChange={(e) =>
                      handleChange("college", "course", e.target.value)
                    }
                    helperText={error ? "Please input other courses" : ""}
                  />
                ) : (
                  <Typography sx={{ width: "150px" }} variant="div">
                    {data.educationalBackground.college.course}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid item md={5}>
            <Stack direction={"column"} spacing={1}>
              <Stack direction={"row"} spacing={2}>
                <Typography sx={{ width: "150px" }} variant="div">
                  Year of passing:
                </Typography>
                {data.type === "add" || data.type === "edit" ? (
                  <TextField
                    label="Year of passing"
                    type="text"
                    style={{ width: "100%" }}
                    error={error}
                    value={data.educationalBackground.elementary.year_graduated}
                    onChange={(e) =>
                      handleChange(
                        "elementary",
                        "year_graduated",
                        e.target.value
                      )
                    }
                    helperText={error ? "This field is required" : ""}
                  />
                ) : (
                  <Typography sx={{ width: "150px" }} variant="div">
                    {data.educationalBackground.elementary.year_graduated}
                  </Typography>
                )}
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <Typography sx={{ width: "150px" }} variant="div">
                  Year of passing:
                </Typography>
                {data.type === "add" || data.type === "edit" ? (
                  <TextField
                    label="year of passing"
                    type="text"
                    style={{ width: "100%" }}
                    error={error}
                    value={
                      data.educationalBackground.high_school.year_graduated
                    }
                    onChange={(e) =>
                      handleChange(
                        "high_school",
                        "year_graduated",
                        e.target.value
                      )
                    }
                    helperText={error ? "This field is required" : ""}
                  />
                ) : (
                  <Typography sx={{ width: "150px" }} variant="div">
                    {data.educationalBackground.high_school.year_graduated}
                  </Typography>
                )}
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <Typography sx={{ width: "150px" }} variant="div">
                  Year of passing:
                </Typography>
                {data.type === "add" || data.type === "edit" ? (
                  <TextField
                    label="year of passing"
                    type="text"
                    style={{ width: "100%" }}
                    value={data.educationalBackground.college.year_graduated}
                    onChange={(e) =>
                      handleChange("college", "year_graduated", e.target.value)
                    }
                    error={error}
                    helperText={error ? "This field is required" : ""}
                  />
                ) : (
                  <Typography sx={{ width: "150px" }} variant="div">
                    {data.educationalBackground.college.year_graduated}
                  </Typography>
                )}
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <Typography sx={{ width: "150px" }} variant="div">
                  Year of passing:
                </Typography>
                {data.type === "add" || data.type === "edit" ? (
                  <TextField
                    label="year of passing"
                    type="text"
                    style={{ width: "100%" }}
                    value={data.educationalBackground.others.year_graduated}
                    onChange={(e) =>
                      handleChange("others", "year_graduated", e.target.value)
                    }
                    error={error}
                    helperText={error ? "This field is required" : ""}
                  />
                ) : (
                  <Typography sx={{ width: "150px" }} variant="div">
                    {data.educationalBackground.others.year_graduated}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

export default EducationalBackground;
