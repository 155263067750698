import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../apiSlice";

const usersAdapter = createEntityAdapter();

const initialState = usersAdapter.getInitialState();

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLoginUsers: builder.query({
      query: () => "/users",
      keepUnusedDataFor: 5,
      providesTags: [{ type: "Users", id: "Operators" }],
    }),
    getUsers: builder.query({
      query: ({
        branch_id,
        id,
        isSuperAdmin,
        page,
        search,
        filters = {},
        rowsPerPage,
      }) => {
        const params = new URLSearchParams();

        if (branch_id) params.append("branch_id", branch_id);
        if (id) params.append("id", id);
        if (isSuperAdmin) params.append("isSuperAdmin", isSuperAdmin);
        if (page) params.append("page", page);
        if (search) params.append("q", search);
        if (filters.position) params.append("position", filters.position);
        if (filters.area_assign)
          params.append("area_assign", filters.area_assign);
        if (rowsPerPage) params.append("rowPerPage", rowsPerPage);
        if (filters.workStatus === 0 || filters.workStatus === 1)
          params.append("status", filters.workStatus);

        return `/employee?${params.toString()}`;
      },
      providesTags: (result, error, arg) => [{ type: "Users", id: "Members" }],
    }),
    addUser: builder.mutation({
      query: (formData) => {
        const headers =
          formData instanceof FormData
            ? {}
            : { "Content-Type": "application/json" };

        return {
          url: "/employee",
          method: "POST",
          body: formData,
          headers,
        };
      },
      invalidatesTags: [
        { type: "Users", id: "Members" },
        { type: "Users", id: "Payments" },
      ],
    }),
    uploadEmployee: builder.mutation({
      query: ({ formData, type }) => ({
        url: `/csv-employee/${type}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: [{ type: "Users", id: "Members" }],
    }),
    uploadAttendance: builder.mutation({
      query: ({ formData, type }) => ({
        url: `/csv-employee/${type}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: [{ type: "Users", id: "Members" }],
    }),
    deleteEmployee: builder.mutation({
      query: ({ id, status, type }) => {
        return {
          url: `/delete/${id}/${status}/${type}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Users", id: "Members" }],
    }),
    getDTR: builder.query({
      query: ({ month, year, period, branch_id, isSuperAdmin }) => {
        const params = new URLSearchParams();
        if (month) params.append("month", month);
        if (year) params.append("year", year);
        if (period) params.append("period", period);
        if (branch_id) params.append("branch_id", branch_id);

        return `/employee-dtr?${params.toString()}`;
      },

      providesTags: [{ type: "DTR", id: "DTR" }],
    }),
    getEmployeeDTR: builder.query({
      query: ({ month, year, period, user_id, branch_id, isSuperAdmin }) => {
        const params = new URLSearchParams();
        if (month) params.append("month", month);
        if (year) params.append("year", year);
        if (period) params.append("period", period);
        if (user_id) params.append("user_id", user_id);
        if (branch_id) params.append("branch_id", branch_id);
        if (isSuperAdmin) params.append("isSuperAdmin", isSuperAdmin);

        return `/employee-dtr/employeeDTR?${params.toString()}`;
      },
      providesTags: [{ type: "DTR", id: "DTR" }],
    }),
    getListEmployee: builder.query({
      query: ({ branch_id, isSuperAdmin }) => {
        const params = new URLSearchParams();
        if (branch_id) params.append("branch_id", branch_id);
        if (isSuperAdmin) params.append("isSuperAdmin", isSuperAdmin);

        return `/employee/list?${params.toString()}`;
      },
      providesTags: [{ type: "Users", id: "Members" }],
    }),
    getListEmployeeWithUsers: builder.query({
      query: ({ branch_id, isSuperAdmin, user_id }) => {
        const params = new URLSearchParams();
        if (branch_id) params.append("branch_id", branch_id);
        if (isSuperAdmin) params.append("isSuperAdmin", isSuperAdmin);
        if (user_id) params.append("user_id", user_id);

        return `/employee/list-with-users?${params.toString()}`;
      },
      providesTags: [{ type: "Users", id: "Members" }],
    }),
    getEmployeeWPayroll: builder.query({
      query: ({ branch_id, isSuperAdmin, user_id }) => {
        // Ensure dates are in the correct format
        const params = new URLSearchParams();
        if (branch_id) params.append("branch_id", branch_id);
        if (isSuperAdmin) params.append("isSuperAdmin", isSuperAdmin);
        if (user_id) params.append("user_id", user_id);
        return `/employee/list-with-payroll?${params.toString()}`;
      },
      providesTags: [{ type: "Users", id: "Members" }],
    }),
    addNewDTR: builder.mutation({
      query: (formData) => {
        const headers =
          formData instanceof FormData
            ? {}
            : { "Content-Type": "application/json" };

        return {
          url: "/employee-dtr",
          method: "POST",
          body: formData,
          headers,
        };
      },
      invalidatesTags: [{ type: "DTR", id: "DTR" }],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useAddUserMutation,
  useGetLoginUsersQuery,
  useUploadEmployeeMutation,
  useUploadAttendanceMutation,

  useDeleteEmployeeMutation,
  useGetDTRQuery,
  useGetEmployeeDTRQuery,
  useGetListEmployeeQuery,
  useAddNewDTRMutation,
  useGetEmployeeWPayrollQuery,
  useGetListEmployeeWithUsersQuery,
} = usersApiSlice;
